import { ComponentProps } from 'react';
import { FormErrors } from '@app/components/UI/Form/index';
import Toggle from '@app/components/UI/Form/Toggle';
import FormControl from '@app/components/UI/Form/FormControl';
import ErrorHelp from '@app/components/UI/Form/ErrorHelp';

interface Props {
  // Errors
  errors?: FormErrors
}

/**
 * A toggle input wrapped in a form control & showing errors.
 */
export default function ToggleInput({
  id,
  label,
  required = false,
  disabled = false,
  checked,
  onChangedValue,
  errors = [],
} : Props & ComponentProps<typeof Toggle>) {

  return <FormControl type="input">
    <Toggle
      id={id}
      label={label}
      checked={checked}
      onChangedValue={onChangedValue}
      required={required}
      disabled={disabled}
    />

    <ErrorHelp id={id} errors={errors} />
  </FormControl>;
}
