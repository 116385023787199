import { cloneElement, HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import { useOpened } from '@app/hooks/useBool';
import { withDisplayName } from '@app/utils/displayName';

interface CollapsibleFuncProps {
  opened: boolean
  toggle: () => void
}

interface Props {
  children: (args: CollapsibleFuncProps) => ReactElement
}

/**
 * Provides a way to create a collapsible component by providing useful props to a rendering function.
 * Pass these props to a {@link Collapsible.Trigger} and a {@link Collapsible.Pane} components.
 *
 * TODO: Create a context to register Collapsible elements by an id/name and auto provide the props to the trigger and pane components.
 */
function Collapsible({
  children,
}: Props) {
  const { opened, toggle } = useOpened(false);

  return children({ opened, toggle });
}

interface MenuProps extends PropsWithChildren {
  opened: boolean
}

/**
 * The collapsible pane, which is hidden/shown on {@link Collapsible.Trigger} clicks.
 */
function Pane({ children, opened }: MenuProps) {
  if (!opened) {
    return null;
  }

  return <>{children}</>;
}

interface TriggerProps extends HTMLAttributes<unknown> {
  children: ReactElement
  toggle: () => void
  opened: boolean
}

/**
 * The collapsible trigger, which hides/shows the {@link Collapsible.Pane} on click.
 */
export function Trigger({
  children,
  toggle,
  opened,
  ...remainingProps
}: TriggerProps) {
  return cloneElement(children, {
    ...remainingProps,
    onClick: (e) => {
      if (children.props.onClick) {
        children.props.onClick(e);
      }

      toggle();
    },
    'aria-expanded': opened ? 'true' : 'false',
  });
}

Collapsible.Trigger = withDisplayName(Trigger, 'Collapsible.Trigger');
Collapsible.Pane = withDisplayName(Pane, 'Collapsible.Pane');

export default Collapsible;
