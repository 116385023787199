import { FormEvent, useState } from 'react';
import { declareRoute } from '@app/router/router';
import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { trans } from '@app/translations';
import TextInput from '@app/components/UI/Form/TextInput';
import tw from 'twin.macro';
import { route } from '@app/router/generator';
import Button from '@app/components/UI/Button';
import Login from '@app/pages/Auth/Login';
import { useMutation } from '@app/api/apollo/useMutation';
import ForgotPasswordMutation from '@graphql/mutation/user/ForgotPassword.graphql';
import Alert from '@app/components/UI/Alert';

interface MutationResponse {
  User: {
    forgotPassword: boolean
  }
}

/**
 * Forgot password page, allowing a user to enter their email and receive a mail to reset their password.
 */
const ForgotPassword = declareRoute(function Page() {
  useDocumentTitle(trans('pages.forgotPassword.documentTitle'));

  const [username, setUsername] = useState<string | null>(null);
  const [forgotPassword, forgotPasswordState] = useMutation<MutationResponse>(ForgotPasswordMutation);

  const hasError =
    !forgotPasswordState.loading &&
    forgotPasswordState.called &&
    (forgotPasswordState.error || !forgotPasswordState.data?.User?.forgotPassword)
  ;
  const hasSuccess = forgotPasswordState.called && forgotPasswordState.data?.User.forgotPassword;

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    forgotPassword({ variables: { email: username } });
  }

  return <Container>
    <h2>{trans('pages.forgotPassword.documentTitle')}</h2>

    {hasSuccess && <Alert variant="success">
      Un email vous a été envoyé pour réinitialiser votre mot de passe.
    </Alert>}

    {hasError && <Alert variant="danger">
      Une erreur est survenue.
    </Alert>}

    <form id="forgot-password" onSubmit={onSubmit}>

      <TextInput<string>
        id="username"
        label="Identifiant"
        type="email"
        value={username}
        onChangedValue={setUsername}
        required
        hint="Un email vous sera envoyé pour réinitialiser votre mot de passe."
        InputProps={{
          autoFocus: true,
          autoComplete: 'username',
          placeholder: 'Votre email',
        }}
      />

      <FormActions>
        <Button href={route(Login)}>Retour à l&apos;identification</Button>
        <Button
          type="submit"
          variant="primary"
          disabled={forgotPasswordState.loading}
        >
          Envoyer un email
        </Button>
      </FormActions>
    </form>
  </Container>;
}, '/forgot-password');

const FormActions = tw.div`flex items-center justify-end mt-10`;
const Container = tw.div`max-w-[500px] w-full`;

export default ForgotPassword;
