import TextInput from '@app/components/UI/Form/TextInput';
import { FormEvent, useState } from 'react';
import tw from 'twin.macro';
import { FormErrorsMap } from '@app/components/UI/Form';
import { FormRootErrors } from '@app/components/UI/Form/FormRootErrors';
import Button from '@app/components/UI/Button';

export interface ShutdownFormData {
  reference?: string | null
  date?: Date | null
  clientName?: string | null
}

interface Props {
  onSubmit: (data: ShutdownFormData) => void
  errors?: FormErrorsMap<keyof ShutdownFormData>
}

export default function CreateShutdownForm({
  onSubmit,
  errors = {},
}: Props) {
  const FormActions = tw.div`flex items-center justify-end mt-10`;

  const [reference, setReference] = useState<string|null>(null);
  const [date, setDate] = useState<Date|null>(null);
  const [clientName, setClientName] = useState<string|null>(null);

  function submit(event: FormEvent<HTMLFormElement>): void {
    // Prevent default to avoid page reload.
    event.preventDefault();

    onSubmit({
      reference,
      date,
      clientName,
    });
  }

  return <form className="mb-10 max-w-screen-md" onSubmit={submit}>
    <FormRootErrors errors={errors?.__root} />

    <fieldset className="mb-1">
      <legend className="font-bold">Données de l&apos;arrêt</legend>
      <TextInput<string>
        id="reference"
        label="Référence de l'arrêt"
        value={reference}
        onChangedValue={setReference}
        InputProps={{
          autoFocus: true,
        }}
        errors={errors?.reference}
      />
      <TextInput<Date>
        type="date"
        id="date"
        label="Date de l'arrêt"
        value={date}
        onChangedValue={setDate}
        required
        errors={errors?.date}
      />
    </fieldset>

    <fieldset className="mb-1">
      <legend className="font-bold">Client lié à l&apos;arrêt</legend>
      <TextInput<string>
        id="client"
        label="Client"
        required
        value={clientName}
        onChangedValue={setClientName}
        errors={errors?.clientName}
      />
    </fieldset>

    <FormActions>
      <Button type="submit" variant="primary">Valider</Button>
    </FormActions>
  </form>;
}
