import { Import } from '@app/models/types/Import';

export interface Shutdown {
  uid: string
  reference: string | null
  status: ShutdownStatus
  date: string
  client: string
  imports: Import[]
  createdBy?: {
    firstname: string
    lastname: string
  }
}

export enum ShutdownStatus {
  InProgress = 'InProgress',
  Closed = 'Closed',
}
