import { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import styled from '@emotion/styled';

interface Props {
  variant?: 'primary' | 'neutral' | 'success'| 'info' | 'warning' | 'danger'
  size?: 'small' | 'medium'
}

export default function Alert({
  variant = 'neutral',
  size = 'medium',
  children,
}: PropsWithChildren<Props>) {
  return <Container variant={variant} size={size} role="alert">
    {children}
  </Container>;
}

const Container = styled.div<Pick<Props, 'variant' | 'size'>>(({
  variant = 'neutral',
  size = 'medium',
}) => [
  tw`
    rounded
    border
    border-solid
    border-transparent
    px-8
    mb-4
  `,
  containerSizes[size],
  containerVariants[variant],
]);

const containerVariants = {
  'primary': tw`bg-primary text-white`,
  'neutral': tw`bg-dark border-base`,
  'success': tw`bg-success text-white`,
  'warning': tw`bg-warning text-white`,
  'info': tw`bg-info text-white`,
  'danger': tw`bg-danger text-white`,
};

const containerSizes = {
  'small': tw`py-2`,
  'medium': tw`py-4`,
};
