import Table, { TableActionsButton } from '@app/components/UI/Table';
import Link from '@app/components/Router/Link';
import ListShutdowns, { ShutdownForListing } from '@app/pages/ListShutdowns/ListShutdowns';
import Pagination from '@app/components/UI/Pagination';
import { toChunks } from '@app/utils/array';
import { formatDate } from '@app/utils/dates';
import ShowShutdown from '@app/pages/ShowShutdown/ShowShutdown';
import { route } from '@app/router/generator';
import DropMenu from '@app/components/UI/DropMenu';
import UpdateShutdown from '@app/pages/UpdateShutdown';
import { formatAuthor } from '@app/models/utils/shutdown';
import { trans } from '@app/translations';

interface Props {
  shutdowns: readonly ShutdownForListing[]
  currentPage: number
  perPage?: number
}

export default function ShutdownsTable({
  shutdowns,
  currentPage,
  perPage = 10,
}: Props) {
  const pages = toChunks(shutdowns, perPage);
  const pageShutdowns = pages[currentPage - 1] ?? [];

  return <>
    <Table
      headers={[
        'Référence',
        'Date',
        'Auteur',
        'Client',
        '', // Actions
      ]}
      rows={pageShutdowns.map(shutdown => <TableItem
        key={shutdown.uid}
        shutdown={shutdown}
      />)}
    />

    <Pagination current={currentPage} pages={Object.keys(pages).map(pageIdx => route(ListShutdowns, {}, {
      page: String(parseInt(pageIdx) + 1),
    }))} />
  </>;
}

function TableItem({ shutdown }: {
  shutdown: ShutdownForListing
}) {
  return <tr>
    <td>
      {shutdown.reference !== null ? (
        <Link to={route(ShowShutdown, { uid: shutdown.uid })}>
          {shutdown.reference}
        </Link>
      ) : (
        <Link to={route(ShowShutdown, { uid: shutdown.uid })}>
          {trans('components.shutdown.noReference')}
        </Link>
      )}
    </td>
    <td>{formatDate(shutdown.date)}</td>
    <td>{formatAuthor(shutdown)}</td>
    <td>{shutdown.client}</td>
    <td className="text-right">
      <TableItemMenu uid={shutdown.uid} />
    </td>
  </tr>;
}

function TableItemMenu({ uid }: { uid: string }) {
  return <DropMenu>
    <DropMenu.Trigger>
      <TableActionsButton />
    </DropMenu.Trigger>
    <DropMenu.Items>
      <DropMenu.Item>
        <Link to={route(ShowShutdown, { uid })}>
          Voir le détail
        </Link>
      </DropMenu.Item>
      <DropMenu.Item>
        <Link to={route(UpdateShutdown, { uid })}>
          Modifier
        </Link>
      </DropMenu.Item>
    </DropMenu.Items>
  </DropMenu>;
}
