export interface Import {
  uid: string
  createdAt: string
  status: ImportStatus
  nbOfLines: number
  nbOfDevices: number
  nbOfCommands: number
  totalAmount: number
  sourceUrl: string
}

export enum ImportStatus {
  Initial = 'Initial',
  Deposed = 'Deposed',
  Processed = 'Processed',
  Rejected = 'Rejected',
}
