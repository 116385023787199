import Button, { ButtonGroup } from '@app/components/UI/Button';
import Icon from '@app/components/UI/Icon';
import tw from 'twin.macro';
import styled from '@emotion/styled';
import { trans } from '@app/translations';
import Card from '@app/components/UI/Card';
import { ComponentProps } from 'react';
import { DeposeImportButton } from '@app/components/Import';
import { Import, ImportStatus } from '@app/models/types/Import';
import { formatDate } from '@app/utils/dates';
import { ShutdownStatus } from '@app/models/types/Shutdown';

interface Props {
  importItem: Import
  isShutdownClosed: ShutdownStatus
}

const statusVariants: Record<
  ImportStatus,
  ComponentProps<typeof Card>['variant']
> = {
  [ImportStatus.Initial]: 'primary',
  [ImportStatus.Deposed]: 'neutral',
  [ImportStatus.Processed]: 'success',
  [ImportStatus.Rejected]: 'danger',
};

export default function ImportPreview({
  importItem,
  isShutdownClosed,
}: Props) {
  const {
    uid,
    sourceUrl,
    status,
    nbOfCommands,
    nbOfDevices,
    nbOfLines,
    totalAmount,
  } = importItem;

  return <Card
    title={trans(`components.import.preview.${status}`, { count: nbOfCommands })}
    variant={statusVariants[status]}
    size="small"
  >
    <div tw="w-full mb-4 md:w-fit md:mb-0">
      <h4>Import du {formatDate(importItem.createdAt)}</h4>
      <p>{nbOfDevices} appareils - {nbOfLines} lignes - {totalAmount} €</p>
    </div>

    <Actions.Container>
      <Actions status={status} uid={uid} sourceUrl={sourceUrl} isShutdownClosed={isShutdownClosed}/>
    </Actions.Container>
  </Card>;
}

function Actions({
  uid,
  status,
  sourceUrl,
  isShutdownClosed,
}: Pick<Import, 'uid' | 'status' | 'sourceUrl'> & Pick<Props, 'isShutdownClosed'>) {
  if (isShutdownClosed === ShutdownStatus.InProgress) {
    switch (status) {
      case ImportStatus.Initial:
        return <ButtonGroup>
          <Button variant="neutral">
            <Icon name="delete" />
            &nbsp;Supprimer l&apos;import
          </Button>
          <DeposeImportButton uid={uid} />
        </ButtonGroup>;
    }
  }
  return <Button variant="neutral" href={sourceUrl} download>
    <Icon name="download" />
    &nbsp;Télécharger source XLS
  </Button>;
}

Actions.Container = styled.div`
  ${tw`
    flex
    flex-wrap
    justify-end
    w-full
    text-center
    md:w-fit
    md:text-left
  `}
`;
