import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareRoute } from '@app/router/router';
import { useEffect } from 'react';
import { absoluteUrl } from '@app/router/generator';
import ListShutdowns from '@app/pages/ListShutdowns';

export default declareRoute(function Home() {
  useDocumentTitle('Home');

  /**
   * Redirect root to shutdowns list.
   */
  useEffect(() => {
    window.location.href = absoluteUrl(ListShutdowns);
  }, []);

  return null;
}, '/');
