import Link from '@app/components/Router/Link';
import Home from '@app/pages/Home';
import { route } from '@app/router/generator';

interface Props {
  title: string
  subtitle: string
  lead: string
  content: string
}

export default function ErrorPageContent({
  title,
  subtitle,
  lead,
  content,
}: Props) {
  return <div>
    <h1>{title}</h1>
    <p>{subtitle}</p>
    <p>{lead}</p>

    <p>{content}</p>

    <Link to={route(Home)} className="fbtn">Page d&apos;accueil</Link>
  </div>;
}
