import { HTMLAttributes } from 'react';
import { FormErrors, FormUtils } from '@app/components/UI/Form';
import tw from 'twin.macro';
import styled from '@emotion/styled';

interface Props extends HTMLAttributes<HTMLParagraphElement>{
  id: string
  errors?: FormErrors
}

const ErrorHelpContainer = styled.div(tw`text-danger text-sm mt-1`);

export default function ErrorHelp({
  id,
  errors = [],
}: Props) {
  const errorsArray = FormUtils.arrayify(errors);

  if (errorsArray.length === 0) {
    return null;
  }

  if (errorsArray.length === 1) {
    const P = ErrorHelpContainer.withComponent('p');
    return <P id={`${id}-desc-error`}>
      {errorsArray[0]}
    </P>;
  }

  return <ErrorHelpContainer id={`${id}-desc-error`}>
    <ul className="input-error-list">
      {errorsArray.map((error, index) => <li key={index}>
        {error}
      </li>)}
    </ul>
  </ErrorHelpContainer>;
}
