import NotFound from '@app/pages/Error/NotFound';
import AppLayout from '@app/layouts/AppLayout';
import { Route } from '@app/router/router';
import Login from '@app/pages/Auth/Login';
import Logout from '@app/pages/Auth/Logout';
import Home from '@app/pages/Home';
import BlankLayout from '@app/layouts/BlankLayout';
import CreateShutdown from '@app/pages/CreateShutdown';
import ListShutdowns from '@app/pages/ListShutdowns';
import ShowShutdown from '@app/pages/ShowShutdown/ShowShutdown';
import UpdateShutdown from '@app/pages/UpdateShutdown/UpdateShutdown';
import NeedsLogin from '@app/guards/NeedsLogin';
import ListUsers from '@app/pages/Admin/ListUsers/ListUsers';
import NeedsAdminRole from '@app/guards/NeedsAdminRole';
import CreateUser from '@app/pages/Admin/CreateUser';
import UpdateUser from '@app/pages/Admin/UpdateUser';
import ForgotPassword from '@app/pages/Auth/ForgotPassword';
import ResetPassword from '@app/pages/Auth/ResetPassword';

export default [
  {
    component: Home,
    layout: AppLayout,
  },
  {
    component: ListShutdowns,
    layout: AppLayout,
    guard: NeedsLogin,
  },
  {
    component: CreateShutdown,
    layout: AppLayout,
    guard: NeedsLogin,
  },
  {
    component: UpdateShutdown,
    layout: AppLayout,
    guard: NeedsLogin,
  },
  {
    component: ShowShutdown,
    layout: AppLayout,
    guard: NeedsLogin,
  },
  /* Admin pages */
  {
    component: ListUsers,
    layout: AppLayout,
    guard: NeedsAdminRole,
  },
  {
    component: CreateUser,
    layout: AppLayout,
    guard: NeedsAdminRole,
  },
  {
    component: UpdateUser,
    layout: AppLayout,
    guard: NeedsAdminRole,
  },
  /* Auth pages */
  {
    component: Login,
    layout: BlankLayout,
  },
  {
    component: Logout,
    layout: BlankLayout,
  },
  {
    component: ForgotPassword,
    layout: BlankLayout,
  },
  {
    component: ResetPassword,
    layout: BlankLayout,
  },
  /* Error pages */
  {
    path: '*',
    component: NotFound,
  },
] satisfies ReadonlyArray<Route> as ReadonlyArray<Route>; // https://github.com/microsoft/TypeScript/issues/13626#issuecomment-1472348545
