import { I18n } from 'i18n-js';
import fr from './fr';

let i18n: I18n;

export function initTranslations(defaultLocale = 'fr') {
  const locale = (navigator.language || navigator.languages[0] || defaultLocale).slice(0, 2);

  i18n = new I18n({ fr });

  i18n.enableFallback = true;
  i18n.translations = { fr };
  i18n.defaultLocale = defaultLocale;
  i18n.locale = locale;
}

/**
 * Stores the preferred locale in the local storage.
 */
export function storeLocale(locale: string) {
  localStorage.setItem('language', locale);
}

/**
 * Switches and stores the preferred locale.
 */
export function switchLocale(locale = 'en') {
  i18n.locale = locale;

  storeLocale(locale);
}

export function trans(...args: Parameters<I18n['t']>) {
  return i18n.t(...args);
}

export function localize(...args: Parameters<I18n['l']>) {
  return i18n.l(...args);
}
