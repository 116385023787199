import { Children, FunctionComponent, ReactElement } from 'react';
import { isOfType } from '@app/utils/types';
import { withDisplayName } from '@app/utils/displayName';

type WithNamedSlotComponent<T extends object = FunctionComponent> = T & {
  __slotName: string
}

interface EmotionWrappedComponent<T extends object = FunctionComponent> {
  props: {
    __EMOTION_TYPE_PLEASE_DO_NOT_USE__: T
  }
}

/**
 * Relies on a specific property to identify a slot of a given name
 */
function ofName(slotType: WithNamedSlotComponent): (child: ReactElement<unknown, FunctionComponent>) => boolean {
  return (child) => {
    // handle the case the slot is an emotion wrapped component (when using the css or tw prop on it)
    if (
      isOfType<EmotionWrappedComponent>(child, 'props') &&
      child?.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__ !== undefined
    ) {
      // The __EMOTION_TYPE_PLEASE_DO_NOT_USE__ property holds the original component type.
      // We're not supposed to use it, so we may find another solution later.
      if (isOfType<WithNamedSlotComponent>(child.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__, '__slotName')) {
        return child.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__.__slotName === slotType.__slotName;
      }
    }

    if (isOfType<WithNamedSlotComponent>(child.type, '__slotName')) {
      return child.type.__slotName === slotType.__slotName;
    }

    return false;
  };
}

/**
 * Find a slot of a given type in the children of a component.
 */
export function findSlotWithName(children, slotType: WithNamedSlotComponent): ReactElement|null {
  return Children.toArray(children).find(ofName(slotType)) as ReactElement|null;
}

export function withSlotName<T extends object>(component: T, slotName: string): WithNamedSlotComponent<T> {
  return withDisplayName(Object.assign(component, { __slotName: slotName }), slotName);
}
