import styled from '@emotion/styled';
import tw from 'twin.macro';
import { PropsWithChildren } from 'react';

interface Props {
  title: string
  variant?: 'primary' | 'neutral' | 'success'| 'info' | 'warning' | 'danger'
  size?: 'small' | 'medium'
}

export default function Card({
  variant = 'neutral',
  size = 'medium',
  title,
  children,
}: PropsWithChildren<Props>) {
  return <Container>
    <Header variant={variant} size={size}>
      {title}
    </Header>

    <Content>
      {children}
    </Content>
  </Container>;
}

const Container = styled(tw.div`mb-8 bg-white`)`
  h1, h2, h3, h4, h5, h6 {
    ${tw`m-0`}
  }

  a {
    ${tw`
      mb-5 w-full whitespace-nowrap
      md:w-fit md:m-2 md:mb-0
    `}
  }
`;

const Header = styled.div<Pick<Props, 'variant' | 'size'>>(({
  variant = 'neutral',
  size = 'medium',
}) => [
  tw`
    rounded-tl-lg
    rounded-tr-lg
    border
    border-solid
    border-transparent
    border-b-0
    px-8
  `,
  headerSizes[size],
  headerVariants[variant],
]);

const headerVariants = {
  'primary': tw`bg-primary text-white`,
  'neutral': tw`bg-dark border-base`,
  'success': tw`bg-success text-white`,
  'warning': tw`bg-warning text-white`,
  'info': tw`bg-info text-white`,
  'danger': tw`bg-danger text-white`,
};

const headerSizes = {
  'small': tw`py-2`,
  'medium': tw`py-4`,
};

const Content = tw.div`
  rounded-bl-lg
  rounded-br-lg
  flex
  flex-col
  items-center
  justify-between
  border
  border-solid
  border-base
  py-4
  px-8
  md:flex-row
`;
