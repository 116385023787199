import styled from '@emotion/styled';
import tw from 'twin.macro';
import Icon from '@app/components/UI/Icon';
import { PropsWithChildren } from 'react';

interface Props {
  opened: boolean
  setOpened: (opened: boolean) => void
}

export default function ResponsiveMenu({
  opened = false,
  setOpened,
  children,
}: PropsWithChildren<Props>) {

  return <>
    <button className="text-slate-200 px-6 text-xl lg:hidden" onClick={() => setOpened(!opened)}>
      {opened ? <Icon name="cross" /> : <Icon name="menu" />}
    </button>

    <Container opened={opened}>
      <ContainerNav>
        {children}
      </ContainerNav>
    </Container>
  </>;
}

const Container = styled.div<Pick<Props, 'opened'>>(({
  opened = false,
}) => [
  tw`
  bg-primary
  items-start
  fixed
  w-full
  top-14 bottom-0 left-0 z-10
  px-6 py-7
  transition-transform ease-in-out duration-300 translate-x-full
  lg:flex
  lg:p-0
  lg:items-center
  lg:static
  lg:translate-x-0`,
  opened && tw`translate-x-0`,
]);

const ContainerNav = styled(tw.div`
  lg:w-full
  lg:flex
`)`
  >div {
    ${tw`
      flex
      lg:items-center
      lg:h-full
    `}
  }
  >div ul {
    ${tw`
      flex
      flex-col
      w-full
      lg:w-fit
      lg:mx-4
      lg:flex-row
    `}
  }
  >div ul li {
    ${tw`
      text-white
      lg:font-bold
      lg:px-2
    `}
  }
  >div ul li a, >div ul li span {
    ${tw`
      flex
      text-white
      items-center
      border-b
      border-slate-50
      border-opacity-20
      py-3
      lg:border-0
      lg:p-0
      lg:justify-center
    `}
  }
`;
