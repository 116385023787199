import { PropsWithChildren } from 'react';
import Header from '@app/layouts/AppLayout/Header';

export default function AppLayout({ children }: PropsWithChildren) {

  return <>
    <Header />

    <main className="flex p-6 w-full max-w-main-content">
      {children}
    </main>

    {/* <Footer />*/}
  </>;
}
