import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareRoute } from '@app/router/router';
import Home from '@app/pages/Home';
import { LinksBreadcrumb } from '@app/components/UI/Breadcrumb';
import { useMutation } from '@app/api/apollo/useMutation';
import CreateShutdownMutation from '@graphql/mutation/shutdown/CreateShutdown.graphql';
import { useNavigate } from 'react-router-dom';
import CreateShutdownForm, { ShutdownFormData } from '@app/pages/CreateShutdown/CreateShutdownForm';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { onCreatedShutdown } from '@graphql/store/shutdown';
import { ROUTE_LIST_SHUTDOWNS } from '@app/constants';
import ShowShutdown from '@app/pages/ShowShutdown';
import { Shutdown } from '@app/models/types/Shutdown';
import { path, route } from '@app/router/generator';

interface MutationResponse {
  CreateShutdown: Shutdown
}

export default declareRoute(function CreateShutdown() {
  useDocumentTitle('Création d\'un arrêt');

  const navigate = useNavigate();
  const [mutate, { error }] = useMutation<MutationResponse>(CreateShutdownMutation, {
    update(cache, { data }) {
      onCreatedShutdown(cache, data!.CreateShutdown);
    },
  });

  const mappedErrors = useMemo(() => {
    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [error]);

  async function submit({ reference, date, clientName }: ShutdownFormData) {
    mutate({
      variables: {
        payload: {
          reference,
          date,
          clientName,
        },
      },
    }).then((result) => {
      navigate(route(ShowShutdown, { uid: result.data!.CreateShutdown.uid }));
    }).catch(onMutateError);
  }

  return <div className="w-full">

    <LinksBreadcrumb links={[
      { label: 'Accueil', href: route(Home) },
      { label: 'Liste des arrêts', href: path(ROUTE_LIST_SHUTDOWNS) },
      { label: 'Création d\'un arrêt', href: '#', active: true },
    ]} />

    <CreateShutdownForm onSubmit={submit} errors={mappedErrors} />
  </div>;
}, '/shutdowns/create');
