import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from '@app/App';

import { initTranslations } from '@app/translations';

initTranslations();

const container = document.getElementById('app') as HTMLElement;

createRoot(container).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
