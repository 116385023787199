import { PropsWithChildren } from 'react';
import EfireLogoDark from '@assets/images/logo-efire-dark.svg';

/**
 * Blank layout for the most simple pages (login, logout, transitions, …)
 */
export default function BlankLayout({ children }: PropsWithChildren) {

  return <>
    <Header />

    <main className="flex justify-center">
      {children}
    </main>
  </>;
}

function Header() {
  return <header className="flex justify-center py-24 px-0">
    <img src={EfireLogoDark} alt="Efire - Arrêt" className="w-[300px]" />
  </header>;
}
