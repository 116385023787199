import { ReactElement } from 'react';
import Menu from '@mui/base/Menu';
import MenuItem, { menuItemClasses } from '@mui/base/MenuItem';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { MenuOwnProps } from '@mui/base/Menu/Menu.types';

export const DropMenuItem = styled(MenuItem)`
  ${tw`
    bg-white
    py-3 px-4
    text-sm
    text-gray-700
    rounded

    hover:(
      bg-slate-50
      text-primary
    )
  `}

  // Override rules for links and buttons in menu items:
  & > a,
  & > button {
    ${tw`
      text-sm
      text-gray-700
      block
      w-full

      hover:(
        text-primary
      )
    `}
  }

  // Disabled menu items:
  &.${menuItemClasses.disabled} {
    ${tw`
      opacity-50
      cursor-not-allowed
    `}

    & > a,
    & > button {
      ${tw`cursor-not-allowed`}
    }
  }
`;

/**
 * Usage:
 *
 * <DropMenu.Items>
 *   <DropMenu.Item>...</DropMenu.Item>
 *   <DropMenu.Item>...</DropMenu.Item>
 * </DropMenu.Items>
 */
function DropMenuItems({ children }: {
  children: Iterable<ReactElement<unknown, typeof DropMenuItems>> | ReactElement<unknown, typeof DropMenuItems>
}) {
  return <>{children}</>;
}

const StyledListbox = tw.ul`
  bg-white
  p-0
  m-0
  box-border
  rounded
  overflow-auto
  outline-none
  border border-base
  shadow-sm
`;

interface SlotProps {
  id: string
  isOpened: boolean
  close: () => void
}

/**
 * @internal Where the DropMenuItems are rendered.
 */
DropMenuItems.Slot = function DropMenuItemsSlot({
  id,
  children,
  anchorEl,
  isOpened,
  close,
}: SlotProps & Omit<MenuOwnProps, 'open' | 'onOpenChange'>) {
  return <Menu
    anchorEl={anchorEl}
    open={isOpened}
    onOpenChange={(opened) => {
      if (!opened) {
        close();
      }
    }}
    slots={{ listbox: StyledListbox }}
    slotProps={{ listbox: { id } }}
  >
    {children}
  </Menu>;
};

export default DropMenuItems;
