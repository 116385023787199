import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareRoute } from '@app/router/router';
import Home from '@app/pages/Home';
import { LinksBreadcrumb } from '@app/components/UI/Breadcrumb';
import { useMutation } from '@app/api/apollo/useMutation';
import UpdateUserMutation from '@graphql/mutation/user/UpdateUser.graphql';
import FindUserQuery from '@graphql/query/user/FindUser.graphql';
import { useLocation, useParams } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { path, route } from '@app/router/generator';
import { ROUTE_LIST_USERS } from '@app/constants';
import UnexpectedError from '@app/errors/UnexpectedError';
import { trans } from '@app/translations';
import { useQuery } from '@apollo/client';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import { User } from '@app/models/types/User';
import UserForm, { UserData } from '@app/components/Form/Admin/User/UserForm';
import Alert from '@app/components/UI/Alert';

interface MutationResponse {
  User: {
    update: {
      uid: string
    }
  }
}

interface FindUserQueryResponse {
  User: {
    find: User
  }
}

interface RedirectFromCreationState {
  userCreated: boolean
}

export default declareRoute(function UpdateUser() {
  useDocumentTitle('Modifier un utilisateur');

  const { uid } = useParams();
  const notFoundHandler = useNotFoundHandler();
  const { userCreated = false } = (useLocation().state ?? {}) as RedirectFromCreationState;

  const query = useQuery<FindUserQueryResponse>(FindUserQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate, mutationState] = useMutation<MutationResponse>(UpdateUserMutation);

  const mappedErrors = useMemo(() => {
    const error = mutationState.error;

    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [mutationState.error]);

  async function submit(payload: UserData) {
    mutate({
      variables: { uid, payload },
    }).catch(onMutateError);
  }

  if (query.error) {
    throw new UnexpectedError(query.error.message, query.error);
  }

  if (query.loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  const user = query.data!.User.find;

  return <div className="w-full">

    <LinksBreadcrumb links={[
      { label: 'Accueil', href: route(Home) },
      { label: 'Liste des utilisateurs', href: path(ROUTE_LIST_USERS) },
      { label: user.email, href: '#', active: true },
      { label: 'Modifier', href: '#', active: true },
    ]} />

    {userCreated && !mutationState.called && <Alert variant="success">
      Utilisateur créé avec succès.
    </Alert>}

    {mutationState.called && mutationState.data?.User && <Alert variant="success">
      Utilisateur modifié avec succès.
    </Alert>}

    <UserForm onSubmit={submit} errors={mappedErrors} initialData={user} />
  </div>;
}, '/users/:uid/update');
