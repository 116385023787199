import { PropsWithChildren, useState } from 'react';
import tw from 'twin.macro';
import TextInput from '@app/components/UI/Form/TextInput';
import Select from '@app/components/UI/Form/Select';
import Button, { ButtonGroup } from '@app/components/UI/Button';
import { ShutdownForListing } from '@app/pages/ListShutdowns/ListShutdowns';
import { ShutdownStatus } from '@app/models/types/Shutdown';
import { trans } from '@app/translations';
import { formatAuthor } from '@app/models/utils/shutdown';

export interface Filters {
  reference?: string|null
  author?: string|null
  status?: string|null
  client?: string|null
  startDate?: Date|null
  endDate?: Date|null
}

interface Props {
  shutdowns: readonly ShutdownForListing[]
  onSubmit: (filters: Filters) => void
}

export default function ShutdownsFilters({
  shutdowns,
  onSubmit,
}: Props) {
  const [reference, setReference] = useState<string|null>(null);
  const [author, setAuthor] = useState<string|null>(null);
  const [status, setStatus] = useState<string|null>(ShutdownStatus.InProgress);
  const [client, setClient] = useState<string|null>(null);
  const [startDate, setStartDate] = useState<Date|null>(null);
  const [endDate, setEndDate] = useState<Date|null>(null);

  const clients = Array.from(new Set(shutdowns.map((shutdown) => shutdown.client)));

  const authors: Record<string, string> = shutdowns.reduce((authors, shutdown) => {
    const authorName = formatAuthor({ createdBy: shutdown.createdBy });
    authors[authorName] = authorName;

    return authors;
  }, {});


  function clear(e) {
    setReference(null);
    setAuthor(null);
    setStatus(null);
    setClient(null);
    setStartDate(null);
    setEndDate(null);

    onSubmit({});
    e.currentTarget.blur();
  }

  function submit(e) {
    e.preventDefault();
    e.currentTarget.blur();
    onSubmit({ reference, author, status, client, startDate, endDate });
  }

  return <form className="mb-10 max-w-screen-lg">
    <FiltersFieldset value="Filtrer par arrêt">
      <FiltersSection>
        <TextInput<string>
          id="reference"
          label="Référence de l'arrêt"
          value={reference}
          onChangedValue={setReference}
          dataList={shutdowns
            .filter(shutdown => shutdown.reference !== null)
            .map(shutdown => [shutdown.uid, shutdown.reference ?? trans('components.shutdown.noReference')])}
        />

        <Select<string>
          id="author"
          label="Auteur de l'arrêt"
          placeholder="Tous"
          options={Object.keys(authors).map(authorName => ({ value: authorName, label: authors[authorName] }))}
          value={author}
          onChangedValue={setAuthor}
          allowEmpty
        />

        <Select<string>
          id="status"
          label="Statut de l'arrêt"
          placeholder="Tous"
          options={Object.values(ShutdownStatus).map(status => ({
            value: status,
            label: trans(`components.shutdown.filters.selectStatus.options.${status}`),
          }))}
          value={status}
          onChangedValue={setStatus}
          allowEmpty
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par client">
      <FiltersSection>
        <TextInput<string>
          id="client"
          label="Client"
          value={client}
          onChangedValue={setClient}
          dataList={clients.map(client => [client, client])}
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par date">
      <FiltersSection>
        <TextInput<Date> type="date" id="startDate" label="Début" value={startDate} onChangedValue={setStartDate} />
        <TextInput<Date> type="date" id="endDate" label="Fin" value={endDate} onChangedValue={setEndDate} />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersActions>
      <ButtonGroup>
        <Button variant="neutral" onClick={clear}>Vider les filtres</Button>
        <Button variant="primary" type="submit" onClick={submit}>
          Appliquer les filtres
        </Button>
      </ButtonGroup>
    </FiltersActions>
  </form>;
}

function FiltersFieldset({ value, children }: { value: string } & PropsWithChildren) {
  return <fieldset className="mb-1">
    <legend className="font-bold">{value}</legend>

    {children}
  </fieldset>;
}

const FiltersSection = tw.div`
  grid grid-cols-1 gap-2
  lg:(
    grid grid-cols-3 gap-4
  )
`;

const FiltersActions = tw.div`mt-8`;

export function filterShutdowns(shutdowns: ShutdownForListing[], filters: Filters) {
  return shutdowns.filter(shutdown => {

    if (shutdown.reference != null) {
      if (filters.reference && !shutdown.reference.includes(filters.reference)) {
        return false;
      }
    }

    if (filters.author) {
      const fullName = formatAuthor({ createdBy: shutdown.createdBy });
      if (fullName !== filters.author) {
        return false;
      }
    }

    if (filters.client && !shutdown.client.includes(filters.client)) {
      return false;
    }

    if (filters.startDate && new Date(shutdown.date) < filters.startDate) {
      return false;
    }

    if (filters.endDate && new Date(shutdown.date) > filters.endDate) {
      return false;
    }

    if (filters.status && shutdown.status !== filters.status) {
      return false;
    }

    // noinspection RedundantIfStatementJS
    return true;
  });
}
