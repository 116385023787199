import tw from 'twin.macro';
import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { findSlotWithName, withSlotName } from '@app/utils/slots';

const Container = withSlotName(
  styled(
    tw.div`flex flex-col justify-between md:mb-7 md:flex-row md:items-center
  `)`
  h1, h2, h3, h4, h5, h6 {
    ${tw`m-0 mb-7 md:mb-2 `}
  }
`, 'ContentHeader.Container');

const Title = withSlotName(tw.div`flex mr-9`, 'ContentHeader.Title');
const Actions = withSlotName(
  styled(
    tw.div`flex flex-col text-center md:flex-row md:text-left md:block md:whitespace-nowrap
  `)`
  // Space between actions:
  > * {
    ${tw`md:!ml-5`}
  },
  * {
    ${tw`mb-7 md:mb-0`}
  }
`, 'ContentHeader.Actions');

function ContentHeader({ children }: PropsWithChildren) {
  const TitleEl = findSlotWithName(children, Title);
  const ActionsEl = findSlotWithName(children, Actions);

  return <Container>
    {TitleEl}
    {ActionsEl}
  </Container>;
}

export default Object.assign(ContentHeader, {
  Title,
  Actions,
});
