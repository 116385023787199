import tw from 'twin.macro';
import Button from '@app/components/UI/Button/Button';
import Icon from '@app/components/UI/Icon';

const StyledTableActionsButton = tw(Button)`
  p-1
  m-0
  leading-tight
  text-lg
  text-light
  bg-transparent focus:bg-transparent hover:bg-transparent
`;

/**
 * Use this within a <DropMenu.Trigger> element to display an actions button on a table row.
 */
function TableActionsButton(props) {
  return <StyledTableActionsButton {...props}>
    <Icon name="bullets" />
  </StyledTableActionsButton>;
}

export default TableActionsButton;
