import { PropsWithChildren, useEffect } from 'react';
import { useAuthContext } from '@app/hooks/useAuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from '@app/pages/Auth/Login';
import AppLayout from '@app/layouts/AppLayout';
import { route } from '@app/router/generator';

/**
 * Checks authentication and redirects to login page if not authenticated.
 */
export default function NeedsLogin({ children }: PropsWithChildren) {
  const { pathname } = useLocation();
  const { authenticated, initialized } = useAuthContext();
  const navigate = useNavigate();

  // Wait for the authentication to be initialized,
  // then, if unauthenticated, redirect to the login page:
  useEffect(() => {
    if (!initialized) {
      // Noop on non-initialized authentication
      return;
    }

    if (!authenticated) {
      // Redirect to the login page if not authenticated
      navigate(route(Login), {
        state: {
          target: pathname,
        },
      });
    }
  }, [authenticated, initialized, pathname, navigate]);

  if (!authenticated) {
    return <AppLayout>
      <main role="main">
        <p>
          Vérification de votre authentification…
        </p>
      </main>
    </AppLayout>;
  }

  return <>{children}</>;
}
