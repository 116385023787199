import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';

if (AppConfig.SENTRY_DSN) {
  Sentry.init({
    dsn: AppConfig.SENTRY_DSN,
    environment: AppConfig.APP_RUNTIME_ENV,
    release: AppConfig.APP_RELEASE,
    ignoreErrors: [
      /GraphQL error: Invalid payload/,
    ],

    // https://docs.sentry.io/platforms/javascript/guides/react/performance/
    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [new BrowserTracing({
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    })],
    // To set a uniform sample rate
    tracesSampleRate: 0.1,
  });
}

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default Sentry;
