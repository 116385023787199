import {
  ApolloClient,
  ApolloLink,
  HttpOptions,
  InMemoryCache,
  Operation,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { ErrorLink, ErrorResponse } from '@apollo/client/link/error';
import { AppGraphQLError, GraphQLErrorCodes } from '@app/api/errors/GraphQLErrorCodes';
import AppConfig from '@app/AppConfig';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import typePolicies from '@app/api/apollo/typePolicies';
import { isOfType } from '@app/utils/types';
import { GraphQLError } from 'graphql';
import Sentry from '@app/sentry';
import Logout from '@app/pages/Auth/Logout';
import { extractFiles } from 'extract-files';
import { absoluteUrl } from '@app/router/generator';

export default new ApolloClient({
  link: createLink({
    uri: AppConfig.API_HOST + '/graphql/',
    credentials: 'include',
  }),
  cache: new InMemoryCache({
    // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
    typePolicies,
  }),
});

/**
 * Create a link for Apollo Client by combining multiple links, depending on the context.
 * For instance, we cannot use a batch link when uploading files, but use a dedicated upload link instead.
 */
function createLink(options: HttpOptions) {
  const links: ApolloLink[] = [
    createGraphQLErrorLink(),
    // Choose proper link on the fly:
    ApolloLink.split(
      // if the operation contains a file upload
      operation => extractFiles(operation).files.size > 0,
      // use the upload link (https://github.com/jaydenseric/apollo-upload-client)
      createUploadLink(options),
      // otherwise use the batch link
      createBatchLink(options),
    ),
  ];

  return ApolloLink.from(links);
}

/**
 * Batch multiple operations into a single HTTP request when available.
 *
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-batch-http/
 * @see https://www.apollographql.com/blog/apollo-client/performance/query-batching/
 */
function createBatchLink(options: HttpOptions) {
  return new BatchHttpLink({
    ...options,
    uri: options.uri + 'batch',
    batchMax: 10,
    batchInterval: 20,
  });
}

/**
 * Global error handling on GraphQL calls.
 * Formats violation errors in the console.
 * Handles special errors like 404 or 403 HTTP error code equivalents.
 *
 * @see https://www.apollographql.com/docs/link/links/error.html
 *
 * @returns {ErrorLink}
 */
function createGraphQLErrorLink() {
  return new ErrorLink(onError);
}

function onError({ graphQLErrors, networkError, operation }: ErrorResponse) {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError: AppGraphQLError) => {
      const { api_problem, message, path, code } = graphQLError;
      let formattedError = `[GraphQL error]: Message "${message}" at path "${path}"`;
      if (code) {
        formattedError += ` (code: ${code})`;
      }

      switch (code) {
        case GraphQLErrorCodes.INVALID_PAYLOAD:
          if (!api_problem) {
            break;
          }

          formattedError += ':';
          api_problem!.violations.forEach(({ type, propertyPath, title }) => {
            formattedError += `\n    - ✗ violation at path "${propertyPath}": "${title}" (code: "${type}")`;
          });

          // Just display the errors in the console, do not capture it in Sentry:
          return console.error(formattedError, graphQLErrors);

        case GraphQLErrorCodes.NOT_FOUND:
          return notFoundErrorHandler(operation, graphQLError);

        case GraphQLErrorCodes.FORBIDDEN:
        case GraphQLErrorCodes.ACCESS_DENIED:
          return forbiddenErrorHandler(operation, graphQLError);

        case GraphQLErrorCodes.CUSTOM_USER_ERROR: {
          return customUserErrorHandler(operation, graphQLError);
        }
      }

      // Capture non handled errors:
      Sentry.captureException(graphQLError);
      console.error(formattedError, graphQLErrors);
    });
  }

  if (networkError) {
    if (
      isOfType<ServerError | ServerParseError>(networkError, 'statusCode') &&
      networkError.statusCode === 401
    ) {
      // Allow a special handler to be registered on invalid auth:
      if (operation && operation.getContext()?.onUnauthorized) {
        // Execute specific callback on unauthorized if provided:
        const handled = operation.getContext()?.onUnauthorized(networkError.message, networkError);

        if (handled !== false) {
          // If the callback does not return false, we consider the error was handled:
          return console.warn(`[Network error]: Handled Unauthorized Error with message "${networkError.message}" with specific callback.`);
        }
      }

      console.warn('[Auth error] Expired JWT token. Disconnect.');

      logout();

      return;
    }

    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
    // Send unexpected network errors to Sentry
    Sentry.captureException(networkError);
  }
}

const notFoundErrorHandler = errorHandlerFactory('onNotFound', 'Not Found');
const forbiddenErrorHandler = errorHandlerFactory('onForbidden', 'Forbidden');
const customUserErrorHandler = errorHandlerFactory(
  'onCustomUserError',
  'Custom User Error',
  (operation: Operation, graphQLError: GraphQLError) => {
    window.alert(graphQLError?.message ?? 'Une erreur inconnue est survenue.');
  },
);

function errorHandlerFactory(
  contextKey: string,
  type: string,
  defaultHandler?: (operation: Operation, graphQLError: GraphQLError) => void,
) {
  return (
    operation: Operation,
    graphQLError: GraphQLError,
  ) => {
    const { message, path } = graphQLError;

    if (operation && operation.getContext()?.[contextKey]) {
      // Execute specific callback if provided:
      const handled = operation.getContext()?.[contextKey](message, graphQLError);

      if (handled !== false) {
        // If the callback does not return false, we consider the error was handled:
        return console.warn(`[GraphQL error]: Handled ${type} error with message "${message}" at path "${path}"`);
      }
    }

    if (!defaultHandler) {
      return console.error(`[GraphQL error]: Unhandled ${type} Error with message "${message}" at path "${path}"`);
    }

    defaultHandler(operation, graphQLError);

    return console.error(`[GraphQL error]: Handled ${type} Error (with default handler) with message "${message}" at path "${path}"`);
  };
}

/**
 * Redirect to the logout page.
 */
function logout() {
  window.location.replace(absoluteUrl(Logout));
}
