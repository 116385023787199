import Link from '@app/components/Router/Link';
import Home from '@app/pages/Home';
import EfireLogo from '@images/logo-efire.svg';
import UserMenu from '@app/layouts/AppLayout/UserMenu';
import PrimaryNav from '@app/layouts/AppLayout/PrimaryNav';
import tw, { theme } from 'twin.macro';
import styled from '@emotion/styled';
import { route } from '@app/router/generator';
import { useState } from 'react';
import ResponsiveMenu from '@app/components/UI/ResponsiveMenu/ResponsiveMenu';

export default function Header() {
  const [opened, setOpened] = useState<boolean>(false);

  return <header className="flex justify-between lg:justify-center h-14 px-0 bg-primary">
    <Header.Brand>
      <Link to={route(Home)} className="flex items-center justify-center">
        <img src={EfireLogo} alt="Efire - Arrêt" className="max-w-full max-h-7" />
        <span className="sr-only">{'Retour à l\'accueil'}</span>
      </Link>

      <span className="text-white font-bold pl-4">Arrêt</span>
    </Header.Brand>

    <ResponsiveMenu opened={opened} setOpened={setOpened} >
      <PrimaryNav />
      <UserMenu />
    </ResponsiveMenu>
  </header>;
}

Header.Brand = styled(tw.div`
  relative flex items-center px-8
  lg:after:(
    content-[""]
    absolute
    top-1/2
    right-0
    -translate-y-1/2
    w-[2px]
    h-7
  )
`)`
  ::after {
    background-color: ${theme`borderColor.base`};
  }
`;

styled(tw.li`
  text-white
  lg:font-bold
  lg:px-2
`)`
  a {
    ${tw`
      text-white
      items-center
      py-3
      lg:justify-center
    `}
  }
`;
