import Button from '@app/components/UI/Button';
import tw from 'twin.macro';
import styled from '@emotion/styled';

interface Props {
  current: number
  pages: ReadonlyArray<string>
}

Pagination.Container = tw.ul`
  flex
  list-none
  my-0 mx-auto
  p-0
  max-w-[900px]
`;

export default function Pagination({
  current,
  pages,
}: Props) {
  const currentIdx = current - 1;
  const previousPage = pages[currentIdx - 1];
  const nextPage = pages[currentIdx + 1];

  return <Pagination.Container>

    <PaginationItem.PreviousContainer>
      <Button variant="neutral" disabled={current <= 1} href={previousPage}>
        Précédent
      </Button>
    </PaginationItem.PreviousContainer>

    {pages.map((page, i) => <PaginationItem
      key={page}
      url={page}
      number={i + 1}
      active={i === currentIdx}
    />)}

    <PaginationItem.NextContainer>
      <Button variant="neutral" disabled={current >= pages.length} href={nextPage}>
        Suivant
      </Button>
    </PaginationItem.NextContainer>

  </Pagination.Container>;
}

PaginationItem.Container = styled(tw.li`mx-2 my-0 text-sm`)`
  a, button {
    ${tw`flex`}
  }
`;
PaginationItem.PreviousContainer = tw(PaginationItem.Container)`mr-auto`;
PaginationItem.NextContainer = tw(PaginationItem.Container)`ml-auto`;

interface PaginationItemProps {
  url: string
  number: number
  active: boolean
}

function PaginationItem({
  url,
  number,
  active,
}: PaginationItemProps) {
  return <PaginationItem.Container>
    <Button
      variant={active ? 'primary' : 'neutral'}
      href={url}
    >
      {number}
    </Button>
  </PaginationItem.Container>;
}
