import { FormEvent, useCallback, useEffect, useState } from 'react';
import { declareRoute } from '@app/router/router';
import { CachePolicies, useFetch } from 'use-http';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@app/components/Router/Link';
import useBool from '@app/hooks/useBool';
import { useAuthContext } from '@app/hooks/useAuthContext';
import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { trans } from '@app/translations';
import Home from '@app/pages/Home';
import TextInput from '@app/components/UI/Form/TextInput';
import tw from 'twin.macro';
import { route } from '@app/router/generator';
import Button from '@app/components/UI/Button';
import ForgotPassword from '@app/pages/Auth/ForgotPassword';

interface LoginRedirectState {
  target?: string | null
}

function useLogin() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { postLogin, authenticated } = useAuthContext();

  const { loading, error, post, response, data } = useFetch('/login', {
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });
  const [erroneousResponse, , setAsErroneousResponse, resetErroneousResponse] = useBool(false);

  const login = useCallback(async (username, password) => {
    // Clear any previous error:
    resetErroneousResponse();

    await post({ username, password });

    if (!response.ok) {
      return setAsErroneousResponse();
    }

    postLogin();
  }, [post, postLogin, resetErroneousResponse, response, setAsErroneousResponse]);

  /**
   * Redirect on authenticated user
   */
  useEffect(() => {
    if (authenticated) {
      // Redirect to home (or previous route before login redirect if any)
      navigate((state as LoginRedirectState)?.target ?? route(Home));
    }
  }, [navigate, authenticated, state]);

  const hasError = Boolean(error || erroneousResponse);

  return {
    login,
    loading,
    error: hasError,
    authenticationErrorMessage: hasError ? (data?.message ?? 'L\'authentification a échouée.') : null,
  };
}

/**
 * Login page for the users.
 */
const Login = declareRoute(function Page() {
  useDocumentTitle(trans('pages.login.documentTitle'));

  const { login, loading, error, authenticationErrorMessage } = useLogin();

  const [username, setUsername] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    login(username, password);
  }

  return <Container>
    <form id="login" onSubmit={onSubmit}>
      <h2>{trans('pages.login.documentTitle')}</h2>

      <TextInput<string>
        id="username"
        label="Identifiant"
        type="email"
        value={username}
        onChangedValue={setUsername}
        required
        InputProps={{
          autoFocus: true,
          autoComplete: 'username',
          placeholder: 'Votre email',
        }}
        errors={error}
      />

      <TextInput<string>
        id="password"
        label="Mot de passe"
        type="password"
        value={password}
        onChangedValue={setPassword}
        required
        InputProps={{
          autoComplete: 'current-password',
        }}
        errors={authenticationErrorMessage}
      />

      <FormActions>
        <Button href={route(Home)}>
          {'Retour à l\'accueil'}
        </Button>

        <Button
          variant="primary"
          type="submit"
          disabled={loading}
        >
          Se connecter
        </Button>
      </FormActions>
    </form>

    <hr className="mt-10" />

    <OtherEntries>
      <Link to={route(ForgotPassword)} className="ml-0 mx-2.5">Mot de passe oublié ?</Link>
    </OtherEntries>
  </Container>;
}, '/login');

const OtherEntries = tw.div`flex items-center justify-end mt-4`;
const FormActions = tw.div`flex items-center justify-end mt-10`;
const Container = tw.div`max-w-[500px] w-full`;

export default Login;
