import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Label } from '@app/components/UI/Form/Label';

interface Props {
  // Input
  id: string
  label: string
  required?: boolean
  disabled?: boolean

  // Value
  checked: boolean
  onChangedValue: (value: boolean) => void
}

/**
 * A toggle switch input. See {@link ToggleInput} for a version to include in a form with a form control + errors.
 */
export default function Toggle({
  id,
  label,
  required = false,
  disabled = false,
  checked,
  onChangedValue,
} : Props) {

  return <Container>
    <Input
      type="checkbox"
      id={id}
      checked={checked}
      onChange={(e) => onChangedValue(e.target.checked)}
      disabled={disabled}
    />
    <Label htmlFor={id} required={required} label={label}>
      <SwitchSlider checked={checked} />
    </Label>
  </Container>;
}

const Container = tw.div`
  m-0
  pl-[calc(40px+theme(spacing.2))]
  relative
  flex
`;

const Input = tw.input`hidden`;

const SwitchSlider = styled.span<Pick<Props, 'checked'>>(({ checked }) => [
  tw`
    absolute
    top-0
    left-0
    w-[40px]
    h-[20px]
    bg-slate-300
    rounded-full
    duration-300

    before:(
      content-['']
      absolute
      w-[16px]
      h-[16px]
      top-[2px]
      left-[2px]
      bg-white
      rounded-full
      duration-300
    )
  `,
  checked && tw`bg-primary`,
  checked && tw`
    before:(
      translate-x-[20px]
      bg-white
      shadow-sm
      shadow-primary-dark
    )
  `,
]);
