import Button from '@app/components/UI/Button';
import { ChangeEvent, useRef } from 'react';
import CreateImportMutation from '@graphql/mutation/import/CreateImport.graphql';
import { useMutation } from '@app/api/apollo/useMutation';
import { formatFloat } from '@app/utils/numbers';
import { GraphQLErrorCodes } from '@app/api/errors/GraphQLErrorCodes';
import { octetsToMo } from '@app/utils/bytes';
import { trans } from '@app/translations';
import { onCreatedImport } from '@graphql/store/import';

interface MutationResponse {
  CreateImport: {
    uid: string
  }
}

interface Props {
  shutdownUid: string
  label?: string
  /** Max file size in octets */
  maxFileSize?: number
  onSuccess?: () => void
  onError?: (errorMessage: string, originalError?) => void
}

/**
 * Button to import a new file to a shutdown, creating a new import line.
 */
export default function NewImportButton({
  shutdownUid,
  label = 'Nouvel import',
  maxFileSize = 10 * 1000 * 1000, // 10 Mo
  onSuccess = () => {},
  onError,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>();
  const [upload, { loading }] = useMutation<MutationResponse>(CreateImportMutation, {
    update(cache, { data }) {
      onCreatedImport(cache, data!.CreateImport, shutdownUid);
    },
    variables: {
      shutdownUid,
    },
    context: {
      /**
       * Noop handler to prevent the default error handler from being called on custom user error from the API.
       * We handle this explicitly in the catch block when calling the `upload` mutation instead.
       */
      onCustomUserError: () => {},
    },
  });

  function selectFile() {
    fileInputRef.current?.click();
  }

  /**
   * Reset the input value so that the user can select the same file again
   */
  function resetInput() {
    fileInputRef.current!.value = '';
  }

  function onSelectedFiled(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      // No selected file
      return;
    }

    // File too big
    if (file.size > maxFileSize) {
      const errorMessage = trans('components.import.errors.fileTooLarge', {
        size: formatFloat(octetsToMo(file.size)),
        limit: formatFloat(octetsToMo(maxFileSize)),
      });

      console.warn(errorMessage);

      onError?.(errorMessage);

      resetInput();

      return;
    }

    upload({
      variables: {
        file,
      },
    })
      .then(onSuccess)
      .catch((e) => {
        console.error(e);

        let errorMessage = 'Une erreur est survenue lors de l\'import du fichier.';

        // For now, simply select the first error in the list:
        switch (e.graphQLErrors?.[0]?.code) {
          case GraphQLErrorCodes.INVALID_PAYLOAD:
            errorMessage = e.graphQLErrors[0].api_problem?.violations[0]?.title ?? 'Erreur inconnue';
            break;
          case GraphQLErrorCodes.CUSTOM_USER_ERROR:
            errorMessage = e.graphQLErrors[0].message;
            break;
        }

        onError?.(errorMessage, e);
      }).finally(() => {
      // Reset the input value so that the user can select the same file again
        resetInput();
      });
  }

  return <Button variant="primary" onClick={selectFile} disabled={loading}>
    {loading && trans('common.loading')}
    {!loading && label}
    {/* Hidden input to select the file */}
    <input
      ref={ref => fileInputRef.current = ref}
      type="file"
      hidden
      accept=".xls,.xlsx"
      onChange={onSelectedFiled}
    />
  </Button>;
}
