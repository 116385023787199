import { Link as RouterLink, LinkProps, useInRouterContext } from 'react-router-dom';
import { useErrorBoundaryContext } from '@app/components/ErrorBoundary';

/**
 * Wrap a React-router-dom Link to reset our application state before navigating.
 * This is required since our {@link ErrorBoundary} component might render an error page,
 * short-circuiting the normal routing process.
 */
export default function Link(props: LinkProps & React.RefAttributes<HTMLAnchorElement>) {
  const isInRouterContext = useInRouterContext();
  const { error, reset } = useErrorBoundaryContext();

  function onClick(e) {
    if (error) {
      reset();
    }

    return props.onClick?.(e);
  }

  if (!isInRouterContext) {
    return <a {...props} onClick={onClick} />;
  }

  return <RouterLink {...props} onClick={onClick} />;
}
