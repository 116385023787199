import { ApolloCache } from '@apollo/client';
import { cachedId, Factories } from '@app/api/apollo/cacheUtils';
import { Types } from '@app/api/apollo/typePolicies';
import { Import } from '@app/models/types/Import';

type ImportRef = Pick<Import, 'uid'>;

/**
 * Update Apollo store on new import created from a shutdown.
 */
export function onCreatedImport(
  store: ApolloCache<unknown>,
  newImport: ImportRef,
  shutdownUid: string,
) {
  // Add the new import to the loaded shutdown
  const shutdownRef = cachedId(store, Types.Shutdown, shutdownUid);
  const addToShutdown = Factories.addToOwnerCollection(shutdownRef, 'imports');
  addToShutdown(store, { data: newImport });
}
