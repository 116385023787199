import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareRoute } from '@app/router/router';
import ContentHeader from '@app/components/UI/ContentHeader/ContentHeader';
import Button from '@app/components/UI/Button';
import Icon from '@app/components/UI/Icon';
import Home from '@app/pages/Home';
import { LinksBreadcrumb } from '@app/components/UI/Breadcrumb';
import Collapsible from '@app/components/UI/Collapsible';
import ShutdownsFilters, { Filters, filterShutdowns } from '@app/pages/ListShutdowns/ShutdownsFilters';
import ShutdownsTable from '@app/pages/ListShutdowns/ShutdownsTable';
import { useQuery } from '@apollo/client';
import ListShutdownsQuery from '@graphql/query/shutdown/ListShutdowns.graphql';
import { usePageNumberFromQuery } from '@app/hooks/usePageNumber';
import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_LIST_SHUTDOWNS } from '@app/constants';
import { trans } from '@app/translations';
import { Shutdown, ShutdownStatus } from '@app/models/types/Shutdown';
import CreateShutdown from '@app/pages/CreateShutdown';
import { path, route } from '@app/router/generator';

export type ShutdownForListing = Pick<Shutdown, 'uid' | 'reference' | 'status' | 'date' | 'client' | 'createdBy'>;

interface ListShutdownsResponse {
  ListShutdowns: ShutdownForListing[]
}

export default declareRoute(function ListShutdowns() {
  useDocumentTitle('Liste des arrêts');

  const navigate = useNavigate();
  const currentPage = usePageNumberFromQuery();
  const [filters, setFilters] = useState<Filters>({
    status: ShutdownStatus.InProgress,
  });

  const { data, loading, error } = useQuery<ListShutdownsResponse>(ListShutdownsQuery);

  function onSubmitFilters(filters: Filters) {
    setFilters(filters);
    // Reset to first page:
    navigate(path(ROUTE_LIST_SHUTDOWNS, { page: '1' }));
  }

  if (loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <Layout>
      <p>{trans('common.loading')}</p>
    </Layout>;
  }

  if (error) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw error;
  }

  const shutdowns = data!.ListShutdowns;
  const filteredShutdowns = sortShutdowns(filterShutdowns(shutdowns, filters));

  return <Layout>
    <Collapsible>
      {({ opened, toggle }) => <>
        <ContentHeader>
          <ContentHeader.Title>
            <h1>Liste des arrêts</h1>
          </ContentHeader.Title>

          <ContentHeader.Actions>
            <Collapsible.Trigger toggle={toggle} opened={opened}>
              <Button variant="neutral">
                <Icon name="filter" />&nbsp;{opened ? 'Cacher les filtres' : 'Modifier les filtres'}
              </Button>
            </Collapsible.Trigger>
            <Button variant="primary" href={route(CreateShutdown)}>
              Ajouter un arrêt
            </Button>
          </ContentHeader.Actions>
        </ContentHeader>

        <Collapsible.Pane opened={opened}>
          <ShutdownsFilters shutdowns={filteredShutdowns} onSubmit={onSubmitFilters} />
        </Collapsible.Pane>
      </>}
    </Collapsible>

    <ShutdownsTable shutdowns={filteredShutdowns} currentPage={currentPage} />
  </Layout>;
}, ROUTE_LIST_SHUTDOWNS);

function Layout({ children }: PropsWithChildren) {
  return <div tw="w-full">

    <LinksBreadcrumb links={[
      { label: 'Accueil', href: route(Home) },
      { label: 'Liste des arrêts', href: '#', active: true },
    ]} />

    {children}
  </div>;
}

/**
 * Sort shutdowns by date, descending (most recent ones first).
 */
function sortShutdowns(shutdowns: ShutdownForListing[]) {
  return shutdowns.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
}
