import { TypePolicies } from '@apollo/client';

export enum Types {
  Shutdown = 'Shutdown',
  Import = 'Import',
  User = 'User',
}

/**
 * @see https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
 */
export default {
  [Types.Shutdown]: {
    keyFields: ['uid'],
  },
  [Types.Import]: {
    keyFields: ['uid'],
  },
  [Types.User]: {
    keyFields: ['uid'],
  },

  Query: {
    fields: {
      User: {
        merge: (existing, incoming) => ({ ...existing, ...incoming }),
        fields: {
          me: {
            merge: (existing, incoming) => ({ ...existing, ...incoming }),
          },
          list: {
            merge: (existing, incoming) => incoming,
          },
        },
      },
    },
  },
} as TypePolicies;
