import { ApolloCache } from '@apollo/client';
import ListShutdownsQuery from '@graphql/query/shutdown/ListShutdowns.graphql';
import { Shutdown } from '@app/models/types/Shutdown';

type ShutdownRef = Pick<Shutdown, 'uid'>;

interface Store {
  ListShutdowns: ShutdownRef[]
}

/**
 * Update Apollo store on new shutdown created.
 * Should make the new shutdown visible on the listing.
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-queries
 */
export function onCreatedShutdown(
  store: ApolloCache<unknown>,
  shutdown: ShutdownRef,
) {
  const query = ListShutdownsQuery;
  // Attempt to read the data from the cache:
  const previousData = store.readQuery<Store>({ query });

  if (!previousData) {
    // If no data was fetched yet, we don't need to do anything
    return;
  }

  const data = {
    ...previousData,
    // Append the newly created shutdown to the list:
    ListShutdowns: [...previousData.ListShutdowns, shutdown],
  };

  // Write the data back to the cache:
  store.writeQuery({ query, data });
}
