import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareRoute } from '@app/router/router';
import Home from '@app/pages/Home';
import { LinksBreadcrumb } from '@app/components/UI/Breadcrumb';
import { useMutation } from '@app/api/apollo/useMutation';
import CreateUserMutation from '@graphql/mutation/user/CreateUser.graphql';
import { useNavigate } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { onCreatedUser } from '@graphql/store/users';
import { route } from '@app/router/generator';
import UserForm, { UserData } from '@app/components/Form/Admin/User/UserForm';
import UpdateUser from '@app/pages/Admin/UpdateUser';

interface MutationResponse {
  User: {
    create: { uid: string }
  }
}

export default declareRoute(function CreateUser() {
  useDocumentTitle('Ajout d\'un utilisateur');

  const navigate = useNavigate();
  const [mutate, { error }] = useMutation<MutationResponse>(CreateUserMutation, {
    update(cache, { data }) {
      onCreatedUser(cache, data!.User.create);
    },
  });

  const mappedErrors = useMemo(() => ({
    __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
    ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
  }), [error]);

  async function submit(payload: UserData) {
    mutate({
      variables: { payload },
    }).then((response) => {
      navigate(route(UpdateUser, { uid: response.data!.User.create.uid }), {
        state: {
          userCreated: true,
        },
      });
    }).catch(onMutateError);
  }

  return <div className="w-full">

    <LinksBreadcrumb links={[
      { label: 'Accueil', href: route(Home) },
      { label: 'Création d\'un utilisateur', href: '#', active: true },
    ]} />

    <UserForm onSubmit={submit} errors={mappedErrors} />
  </div>;
}, '/users/create');
