import { PropsWithChildren, useEffect } from 'react';
import { useAuthContext } from '@app/hooks/useAuthContext';
import NeedsLogin from '@app/guards/NeedsLogin';
import NotFoundError from '@app/errors/NotFoundError';

/**
 * Checks authentication and redirects to login page if not authenticated using {@link NeedsLogin}.
 * Then, check the user roles and renders a 404 page if not authorized as admin.
 */
export default function NeedsAdminRole({ children }: PropsWithChildren) {
  const { authenticated, initialized, isAdmin } = useAuthContext();

  // Wait for the authentication to be initialized and to be authenticated:
  useEffect(() => {
    if (!initialized) {
      // Noop on non-initialized authentication
      return;
    }

    // Once authenticated, check the user roles:
    if (authenticated && !isAdmin) {
      throw new NotFoundError(
        'Insufficient permissions. This page requires the admin role. Will render a 404 page instead.',
      );
    }
  }, [authenticated, initialized, isAdmin]);

  return <NeedsLogin>{children}</NeedsLogin>;
}
