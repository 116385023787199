import styled from '@emotion/styled';
import tw from 'twin.macro';
import { useAuthContext } from '@app/hooks/useAuthContext';
import Link from '@app/components/Router/Link';
import Logout from '@app/pages/Auth/Logout';
import { route } from '@app/router/generator';

export default function UserMenu() {
  const { authenticated, profile } = useAuthContext();

  if (!authenticated) {
    return null;
  }

  return <UserMenu.Container>
    <UserMenu.List>
      <UserMenu.Entry tw="capitalize">
        <span>
          {`${profile.firstname} ${profile.lastname}`}
        </span>
      </UserMenu.Entry>
      <UserMenu.Entry>
        <Link to={route(Logout)}>
          Déconnexion
        </Link>
      </UserMenu.Entry>
    </UserMenu.List>
  </UserMenu.Container>;
}

UserMenu.Container = tw.div`
  items-start
`;

UserMenu.List = tw.ul``;

UserMenu.Entry = styled(tw.li``)`
  a:last-of-type {
    ${tw`
      border-none
    `}
  }
`;
