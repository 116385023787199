import Table, { TableActionsButton } from '@app/components/UI/Table';
import Pagination from '@app/components/UI/Pagination';
import { toChunks } from '@app/utils/array';
import ListUsers, { User } from '@app/pages/Admin/ListUsers/ListUsers';
import { route } from '@app/router/generator';
import DropMenu from '@app/components/UI/DropMenu';
import Link from '@app/components/Router/Link';
import UpdateUser from '@app/pages/Admin/UpdateUser';

interface Props {
  users: readonly User[]
  currentPage: number
  perPage?: number
}

export default function UsersTable({
  users,
  currentPage,
  perPage = 10,
}: Props) {
  const pages = toChunks(users, perPage);
  const pageUsers = pages[currentPage - 1] ?? [];

  return <>
    <Table
      headers={[
        'Email',
        'X3 Ref.',
        'Prénom',
        'Nom',
        'Rôle(s)',
        '', // Actions
      ]}
      rows={pageUsers.map(user => <TableItem
        key={user.uid}
        user={user}
      />)}
    />

    <Pagination current={currentPage} pages={Object.keys(pages).map(pageIdx => route(ListUsers, {}, {
      page: String(parseInt(pageIdx) + 1),
    }))} />
  </>;
}

function TableItem({ user }: {
  user: User
}) {
  return <tr>
    <td>{user.email}</td>
    <td>{user.externalId}</td>
    <td>{user.firstname}</td>
    <td>{user.lastname}</td>
    <td>{user.isAdmin ? 'admin' : 'user'}</td>
    <td className="text-right">
      <TableItemMenu uid={user.uid} />
    </td>
  </tr>;
}

function TableItemMenu({ uid }: { uid: string }) {
  return <DropMenu>
    <DropMenu.Trigger>
      <TableActionsButton />
    </DropMenu.Trigger>
    <DropMenu.Items>
      <DropMenu.Item>
        <Link to={route(UpdateUser, { uid })}>
          Modifier
        </Link>
      </DropMenu.Item>
    </DropMenu.Items>
  </DropMenu>;
}
