/**
 * Add a display name to a component.
 *
 * Required to do so explicitly mainly for our Storybook to always have a proper display name on generated code
 * examples from stories.
 * Otherwise, we might get thing like un-prefixed namespaced components
 * or Styled<div> for styled component in production mode.
 */
export function withDisplayName<T extends object>(component: T, displayName: string): T {
  return Object.assign(component, { displayName });
}
