import tw from 'twin.macro';
import styled from '@emotion/styled';

interface Props {
  headers: string[]
  rows: React.ReactNode[]
}

export default function Table({
  headers,
  rows,
}: Props) {
  return <Container>
    <table>
      <thead>
        <tr>
          {headers.map(header => <th key={header}>{header}</th>)}
        </tr>
      </thead>

      <tbody>
        {rows.map(row => row)}
      </tbody>
    </table>
  </Container>;
}

const Container = styled.div`
  ${tw`
    border
    rounded-xl
    overflow-hidden
    mb-12
  `}
  // Allow horizontal scrolling on small screens
  ${tw`max-lg:(overflow-x-auto)`}
`;
