import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareRoute } from '@app/router/router';
import Home from '@app/pages/Home';
import { LinksBreadcrumb } from '@app/components/UI/Breadcrumb';
import { useParams } from 'react-router-dom';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import { useQuery } from '@apollo/client';
import ShowShutdownQuery from '@graphql/query/shutdown/ShowShutdown.graphql';
import ContentHeader from '@app/components/UI/ContentHeader';
import { formatDate } from '@app/utils/dates';
import Button from '@app/components/UI/Button';
import Icon from '@app/components/UI/Icon';
import { ROUTE_LIST_SHUTDOWNS } from '@app/constants';
import { ImportPreview, NewImportButton } from '@app/components/Import';
import { useState } from 'react';
import Alert from '@app/components/UI/Alert';
import { trans } from '@app/translations';
import UpdateShutdown from '@app/pages/UpdateShutdown';
import { Import } from '@app/models/types/Import';
import { Shutdown, ShutdownStatus } from '@app/models/types/Shutdown';
import CloseShutdownMutation from '@graphql/mutation/shutdown/CloseShutdown.graphql';
import { useMutation } from '@app/api/apollo/useMutation';
import { onMutateError } from '@graphql/utils';
import { path, route } from '@app/router/generator';
import { formatAuthor } from '@app/models/utils/shutdown';
import tw from 'twin.macro';

interface ShowShutdownResponse {
  FindShutdown: Shutdown
}

interface CloseShutdownMutationResponse {
  CloseShutdown: Shutdown
}

const classNamesShutdownsStatus: Record<ShutdownStatus, string> = {
  [ShutdownStatus.InProgress]: 'text-primary',
  [ShutdownStatus.Closed]: 'text-success',
};

export default declareRoute(function ShowShutdown() {
  useDocumentTitle('Détail d\'un arrêt');

  // LATER: use a better alerting context for the whole page,
  // showing only last relevant alert / resetting on successful actions?
  const [successfulImport, setSuccessfulImport] = useState<boolean>(false);
  const [failedImport, setFailedImport] = useState<string|null>(null);

  const { uid } = useParams();
  const notFoundHandler = useNotFoundHandler();

  const { data, loading, error } = useQuery<ShowShutdownResponse>(ShowShutdownQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate] = useMutation<CloseShutdownMutationResponse>(CloseShutdownMutation);

  if (loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  if (error) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw error;
  }

  const shutdown = data!.FindShutdown;
  const isClosed = shutdown.status === ShutdownStatus.Closed ?? true;
  const imports = shutdown.imports ?? [];

  const importsSortedByDates = sortImports(imports);

  function onClick() {
    const result = window.confirm('Êtes-vous certain de vouloir clore cet arrêt ? ' +
      'Vous pourrez toujours le consulter ' +
      'mais ne pourrez plus ajouter de nouvel import ou effectuer des modifications.');
    if (result) {
      mutate({
        variables: {
          uid,
        },
      }).then(() => {
        // remain on current page
      }).catch(onMutateError);
    }
  }

  const ContainerButton = tw.div`flex justify-end`;

  return <Layout reference={shutdown.reference ?? trans('components.shutdown.noReference')} clientName={shutdown.client}>

    {successfulImport &&
        <Alert variant="success" size="small">
          <p>Importé avec succès. Le fichier est valide et peut être traité</p>
        </Alert>
    }

    {failedImport && <Alert variant="danger" size="small">
      <p>{failedImport}</p>
    </Alert>}

    <ContentHeader>
      <ContentHeader.Title tw="flex-col mb-7 md:mb-0">
        <h1>{shutdown.client}</h1>
        <span className="text-xl">
          {shutdown?.date && <> {formatDate(shutdown.date)} </>}
            - {formatAuthor(shutdown)}
            - <span className={classNamesShutdownsStatus[shutdown.status]}>
            {trans(`pages.showShutdown.shutdownStatus.${shutdown.status}`)}
          </span>
        </span>
      </ContentHeader.Title>

      {!isClosed && <ContentHeader.Actions>
        <Button variant="neutral" href={route(UpdateShutdown, { uid: shutdown.uid })}>
          <Icon name="edit"/>
            &nbsp;&nbsp;Modifier l&apos;arrêt
        </Button>

        <NewImportButton
          shutdownUid={shutdown.uid}
          onSuccess={() => {
            setSuccessfulImport(true);
            setFailedImport(null);
          }}
          onError={(e) => {
            setSuccessfulImport(false);
            setFailedImport(e);
          }} />
      </ContentHeader.Actions>}
    </ContentHeader>

    {importsSortedByDates.map((importItem) => <ImportPreview
      key={importItem.uid}
      importItem={importItem}
      isShutdownClosed={shutdown.status}
    />)}

    {!isClosed && <ContainerButton>
      <Button
        variant="danger"
        onClick={onClick}
      >
        Clôturer l&apos;arrêt
      </Button>
    </ContainerButton>
    }
  </Layout>;
}, '/shutdowns/:uid');

function Layout({ children, reference, clientName }: {
  children: React.ReactNode
  reference: string
  clientName: string
}) {
  return <div tw="w-full">
    <LinksBreadcrumb links={[
      { label: 'Accueil', href: route(Home) },
      { label: 'Liste des arrêts', href: path(ROUTE_LIST_SHUTDOWNS) }, // Due to a cyclic dependency, we can't use route(ListShutdowns) :'(
      { label: clientName, href: '#' },
      { label: reference, href: '#', active: true },
    ]} />
    {children}
  </div>;
}

/**
 * Sort imports by date, descending (most recent ones first).
 */
function sortImports(imports: Import[]) {
  return Array.from(imports).sort(
    (a, b) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
}
