export function sequence(count: number, start = 0): number[] {
  return Array.from({ length: count }, (_, i) => i + start);
}

/**
 * Split an array into chunks of a given size.
 */
export function toChunks<T>(arr: readonly T[], chunkSize: number): readonly T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);

    chunks.push(chunk);
  }

  return chunks;
}
