import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareRoute } from '@app/router/router';
import ContentHeader from '@app/components/UI/ContentHeader/ContentHeader';
import Button from '@app/components/UI/Button';
import Icon from '@app/components/UI/Icon';
import Home from '@app/pages/Home';
import { LinksBreadcrumb } from '@app/components/UI/Breadcrumb';
import Collapsible from '@app/components/UI/Collapsible';
import { useQuery } from '@apollo/client';
import ListUsersQuery from '@graphql/query/user/ListUsers.graphql';
import { usePageNumberFromQuery } from '@app/hooks/usePageNumber';
import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_LIST_USERS } from '@app/constants';
import { trans } from '@app/translations';
import UsersTable from '@app/pages/Admin/ListUsers/UsersTable';
import UsersFilters, { Filters, filterUsers } from '@app/pages/Admin/ListUsers/UsersFilters';
import CreateUser from '@app/pages/Admin/CreateUser';
import { path, route } from '@app/router/generator';

export interface User {
  uid: string
  email: string
  externalId: string
  firstname: string
  lastname: string
  isAdmin: boolean
  createdAt: string
}

interface ListUsersResponse {
  User: {
    list: User[]
  }
}

export default declareRoute(function ListShutdowns() {
  useDocumentTitle('Liste des utilisateurs');

  const navigate = useNavigate();
  const currentPage = usePageNumberFromQuery();
  const [filters, setFilters] = useState<Filters>({});

  const { data, loading, error } = useQuery<ListUsersResponse>(ListUsersQuery);

  function onSubmitFilters(filters: Filters) {
    setFilters(filters);
    // Reset to first page:
    navigate(path(ROUTE_LIST_USERS, { page: '1' }));
  }

  if (loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <Layout>
      <p>{trans('common.loading')}</p>
    </Layout>;
  }

  if (error) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw error;
  }

  const users = data!.User.list;
  const filteredUsers = sortUsers(filterUsers(users, filters));

  return <Layout>
    <Collapsible>
      {({ opened, toggle }) => <>
        <ContentHeader>
          <ContentHeader.Title>
            <h1>Liste des utilisateurs</h1>
          </ContentHeader.Title>

          <ContentHeader.Actions>
            <Collapsible.Trigger toggle={toggle} opened={opened}>
              <Button variant="neutral">
                <Icon name="filter" />&nbsp;{opened ? 'Cacher les filtres' : 'Modifier les filtres'}
              </Button>
            </Collapsible.Trigger>
            <Button variant="primary" href={route(CreateUser)}>
              Ajouter un utilisateur
            </Button>
          </ContentHeader.Actions>
        </ContentHeader>

        <Collapsible.Pane opened={opened}>
          <UsersFilters users={filteredUsers} onSubmit={onSubmitFilters} />
        </Collapsible.Pane>
      </>}
    </Collapsible>

    <UsersTable users={filteredUsers} currentPage={currentPage} />
  </Layout>;
}, ROUTE_LIST_USERS);

function Layout({ children }: PropsWithChildren) {
  return <div tw="w-full">

    <LinksBreadcrumb links={[
      { label: 'Accueil', href: route(Home) },
      { label: 'Liste des utilisateurs', href: '#', active: true },
    ]} />

    {children}
  </div>;
}

/**
 * Sort users by email, alphabetically.
 */
function sortUsers(users: User[]) {
  return users.sort((a, b) => a.email.localeCompare(b.email));
}
