import styled from '@emotion/styled';
import tw from 'twin.macro';
import Link from '@app/components/Router/Link';
import ListShutdowns from '@app/pages/ListShutdowns/ListShutdowns';
import ListUsers from '@app/pages/Admin/ListUsers/ListUsers';
import { useAuthContext } from '@app/hooks/useAuthContext';
import { route } from '@app/router/generator';

export default function PrimaryNav() {

  const { isAdmin } = useAuthContext();

  return <PrimaryNav.Container>
    <PrimaryNav.List>
      <PrimaryNav.Entry>
        <Link to={route(ListShutdowns)}>Liste des arrêts</Link>
      </PrimaryNav.Entry>

      {isAdmin && <PrimaryNav.Entry>
        <Link to={route(ListUsers)}>Liste des utilisateurs</Link>
      </PrimaryNav.Entry>}
    </PrimaryNav.List>
  </PrimaryNav.Container>;
}

PrimaryNav.Container = tw.div`
  flex-1
`;

PrimaryNav.List = tw.ul`
  items-start
`;

PrimaryNav.Entry = styled(tw.li`
  w-full
  lg:w-fit
`)`
`;
