import { ApolloCache, gql } from '@apollo/client';
import { Types } from '@app/api/apollo/typePolicies';

/**
 * Helper to update Apollo cache to add an item to a collection property held by an owner.
 * E.g: Add a comment to a post in its `comments` property.
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-interaction/#example-updating-the-cache-after-a-mutation
 *
 * @param ownerRef Cached ID of the owner computed using {@link cachedId}
 * @param ownerCollectionField Name of the property holding the collection on the parent object
 * @param idField Property acting as uq id for the type of data
 */
function addItemToCollection(
  ownerRef: string | undefined,
  ownerCollectionField: string,
  idField = 'uid',
) {
  return (cache: ApolloCache<unknown>, { data }) => {
    cache.modify({
      id: ownerRef,
      fields: {
        [ownerCollectionField](existing = []) {
          return [...existing, cache.writeFragment({
            data,
            fragment: gql`
              fragment NewItem on ${data.__typename} {
                ${idField}
              }
            `,
          })];
        },
      },
    });
  };
}

/**
 * @see https://www.apollographql.com/docs/react/caching/cache-interaction/#obtaining-an-objects-cache-id
 */
export function cachedId(
  store: ApolloCache<unknown>,
  typename: Types,
  id,
  idField = 'uid',
) {
  return store.identify({ __typename: typename, [idField]: id });
}

export const Factories = {
  addToOwnerCollection: addItemToCollection,
};
