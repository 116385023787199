import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import ErrorHelp from '@app/components/UI/Form/ErrorHelp';
import { FormErrors, FormUtils } from '@app/components/UI/Form';
import { Label } from '@app/components/UI/Form/Label';
import FormControl from '@app/components/UI/Form/FormControl';
import tw from 'twin.macro';
import styled from '@emotion/styled';

type ValueTypes = string|number;

interface Props<ValueType extends ValueTypes = string> {
  // Input
  id: string
  type?: 'string' | 'number'
  label: string
  required?: boolean
  disabled?: boolean
  hint?: string
  /** Allow to select the empty option */
  allowEmpty?: boolean
  emptyLabel?: string
  placeholder?: string

  // props overrides
  LabelProps?: JSX.IntrinsicElements['label']
  SelectProps?: JSX.IntrinsicElements['select']
  OptionsProps?: JSX.IntrinsicElements['option']

  // Value
  options: Array<{
    label: string
    value: ValueType
  }>
  value?: ValueType | null
  onChangedValue?: (value: ValueType|null, e: React.ChangeEvent<HTMLSelectElement>) => void
  /** @default true */
  normalizeEmptyStringToNull?: boolean

  // Errors
  errors?: FormErrors
}

export default function Select<ValueType extends ValueTypes = string>({
  id,
  type = 'string',
  label,
  required = false,
  allowEmpty = false,
  emptyLabel = 'Aucun',
  placeholder = 'Sélectionnez une option',
  disabled = false,
  hint,
  options,
  value,
  onChangedValue,
  errors = [],
  normalizeEmptyStringToNull = true,
  SelectProps = {},
  LabelProps = {},
  OptionsProps = {},
}: Props<ValueType>) {
  const hasError = FormUtils.hasError(errors);

  function onChange(event: ChangeEvent<HTMLSelectElement>): void {
    if (!onChangedValue) {
      return;
    }

    let normalizedValue: (number | string | null) = event.target.value;

    if (normalizeEmptyStringToNull && '' === normalizedValue) {
      normalizedValue = null;
    }

    if (normalizedValue !== null && type === 'number') {
      normalizedValue = parseInt(normalizedValue, 10);
    }

    return onChangedValue(normalizedValue as ValueType | null, event);
  }

  return <FormControl type="select" disabled={disabled} hasError={hasError}>
    <Label required={required} htmlFor={id} label={label} hint={hint} {...LabelProps} />

    <div className="select">
      <Select.Input
        className={clsx({
          'select--error': hasError,
        })}
        {...(hasError ? {
          'aria-describedby': `${id}-desc-error`,
        } : {})}
        id={id}
        name={id}
        disabled={disabled}
        required={required}
        value={value ?? ''}
        onChange={onChange}
        {...SelectProps}
      >
        <>
          {!required && allowEmpty
            // Empty, selectable option:
            ? <option value="">{emptyLabel}</option>
            // Placeholder, non-selectable option for select with no allowed empty option:
            : <option value="" disabled hidden>{placeholder}</option>
          }
          {options
            .slice()
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(({ label, value: optionValue }, index) => <SelectOption<ValueType>
              key={index}
              label={label}
              value={optionValue}
              required={required}
              OptionProps={OptionsProps}
            />)}
        </>
      </Select.Input>
    </div>

    <ErrorHelp id={id} errors={errors} />
  </FormControl>;
}

Select.Input = styled.select<JSX.IntrinsicElements['select']>(({
  disabled,
}) => [
  disabled && tw`!cursor-not-allowed`,
]);

interface SelectOptionProps<ValueType extends ValueTypes = string> {
  label: string
  value: ValueType
  required?: boolean
  OptionProps?: JSX.IntrinsicElements['option']
}

export function SelectOption<ValueType extends ValueTypes>({
  label,
  value,
  OptionProps = {},
}: SelectOptionProps<ValueType>) {
  return <option
    value={value}
    {...OptionProps}
  >
    {label}
  </option>;
}

