import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import Link from '@app/components/Router/Link';
import { trans } from '@app/translations';

Breadcrumb.Container = styled.ul([
  tw`flex flex-wrap m-0 mb-7`,
]);

function Breadcrumb({
  children,
}: PropsWithChildren) {
  return <nav>
    <Breadcrumb.Container>
      {children}
    </Breadcrumb.Container>
  </nav>;
}

interface ItemProps {
  active?: boolean
}

Breadcrumb.Item = styled.li<ItemProps>(({ active }) => [
  tw`
    flex
    relative
    my-1 mx-0 ml-2.5
    pl-5

    before:(
      absolute
      -translate-y-1/2
      top-1/2
      left-0
      content-["\e905"]
      text-primary
      text-[0.625rem]
      font-icons
      font-normal
    )
    first-of-type:(
      ml-0
      pl-0
    )
    first-of-type:before:(
      content-none
    )
  `,
  active && tw`font-bold`,
]);

interface LinksBreadcrumbProps {
  links: ReadonlyArray<{
    label: string | null
    href: string
    active?: boolean
  }>
}

/**
 * Exposes a {@link Breadcrumb} as a simple list of {@link Link} components.
 */
export function LinksBreadcrumb({
  links,
}: LinksBreadcrumbProps) {
  return <Breadcrumb>
    {links.map(({
      label,
      href,
      active = false,
    }, index) => <Breadcrumb.Item key={index} active={active}>
      <Link to={href}>
        {label ?? trans('components.shutdown.noReference')}
      </Link>
    </Breadcrumb.Item>)}
  </Breadcrumb>;
}

export default Breadcrumb;
