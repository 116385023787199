import tw from 'twin.macro';

interface Props {
  required?: boolean
}

export function RequiredHint({ required = false }: Props) {
  if (!required) {
    return null;
  }

  return <Container>
    <span className="sr-only">Champ obligatoire</span>
  </Container>;
}

const Container = tw.span`before:(
  pl-0.5
  content-['*']
)`;
