import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareRoute } from '@app/router/router';
import Home from '@app/pages/Home';
import { LinksBreadcrumb } from '@app/components/UI/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { onMutateError } from '@graphql/utils';
import ShowShutdownQuery from '@graphql/query/shutdown/ShowShutdown.graphql';
import ContentHeader from '@app/components/UI/ContentHeader';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import UpdateShutdownForm from '@app/pages/UpdateShutdown/UpdateShutdownForm';
import UpdateShutdownMutation from '@graphql/mutation/shutdown/UpdateShutdown.graphql';
import { useMutation } from '@app/api/apollo/useMutation';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import UnexpectedError from '@app/errors/UnexpectedError';
import { trans } from '@app/translations';
import ShowShutdown from '@app/pages/ShowShutdown/ShowShutdown';
import { Shutdown, ShutdownStatus } from '@app/models/types/Shutdown';
import { ROUTE_LIST_SHUTDOWNS } from '@app/constants';
import { ShutdownFormData } from '@app/pages/CreateShutdown/CreateShutdownForm';
import { useMemo } from 'react';
import { path, route } from '@app/router/generator';

interface ShowShutdownQueryResponse {
  FindShutdown: Shutdown
}

interface MutationResponse {
  UpdateShutdown: Shutdown
}

export default declareRoute(function UpdateShutdown() {
  useDocumentTitle('Modifier un arrêt');

  const { uid } = useParams();
  const navigate = useNavigate();
  const notFoundHandler = useNotFoundHandler();

  const query = useQuery<ShowShutdownQueryResponse>(ShowShutdownQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate, mutationState] = useMutation<MutationResponse>(UpdateShutdownMutation, {
    update() {
      // Noop: since the mutation is referring to an existing object in Apollo cache,
      // there is no need to add this object in any collection,
      // and the fields returned by the mutation are already updated automatically on the corresponding object.
    },
  });

  const mappedErrors = useMemo(() => {
    const error = mutationState.error;

    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [mutationState.error]);

  async function submit({ reference, date, clientName }: ShutdownFormData) {
    mutate({
      variables: {
        uid,
        payload: {
          reference,
          date,
          clientName,
        },
      },
    }).then(() => {
      // redirect to the new template
      navigate(route(ShowShutdown, { uid: uid }));
    }).catch(onMutateError);
  }

  if (query.error) {
    throw new UnexpectedError(query.error.message, query.error);
  }

  if (query.loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  const shutdown = query.data!.FindShutdown;

  const isClosed = shutdown.status === ShutdownStatus.Closed;

  return <div tw="w-full">
    <LinksBreadcrumb links={[
      { label: 'Accueil', href: route(Home) },
      { label: 'Liste des arrêts', href: path(ROUTE_LIST_SHUTDOWNS) },
      { label: shutdown.client, href: '#' },
      { label: shutdown.reference, href: route(ShowShutdown, { uid: shutdown.uid }) },
      { label: 'Modifier l\'arrêt', href: '#', active: true },
    ]} />
    <ContentHeader>
      <ContentHeader.Title>
        <h1>Modifier l&apos;arrêt</h1>
      </ContentHeader.Title>
    </ContentHeader>
    {isClosed && <p>Vous ne pouvez pas modifier un arrêt terminé.</p>}
    {!isClosed && <UpdateShutdownForm
      shutdown={shutdown}
      onSubmit={submit}
      errors={mappedErrors}
    />}
  </div>;

}, '/shutdown/:uid/update');
