import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import Link from '@app/components/Router/Link';
import tw from 'twin.macro';
import styled from '@emotion/styled';
import { isOfType } from '@app/utils/types';

const StyledButton = styled.button<ContainerProps>(({
  variant = 'default',
  disabled = false,
}) => [
  // base styles
  tw`
    px-5 py-3
    justify-center
    rounded
    cursor-pointer
    border
    transition-colors
    w-full
    md:w-fit
  `,
  // variants
  Button.variants[variant],
  // disabled states
  disabled
    ? tw`opacity-40 cursor-not-allowed`
    : tw`
        focus:(
          bg-accessibility-highlight
          border-primary
        )
      `
  ,
]);

// Button variants:
Button.variants = {
  default: tw`
    bg-transparent border-transparent text-primary
    hover:(!text-primary-dark !border-transparent)
    focus:(!text-primary-dark !border-transparent)
  `,
  primary: tw`
    bg-primary border-primary text-white
    hover:(text-white bg-primary-dark border-primary-dark)
    focus:(text-white bg-primary-dark border-primary-dark)
  `,
  danger: tw`
    hover:(text-danger !border-danger)
    focus:(text-danger !border-danger)
  `,
  neutral: tw`
    bg-white text-primary
    hover:(text-primary-dark border-primary)
    focus:(text-primary-dark border-primary)
  `,
};

export const ButtonGroup = styled(tw.div`
flex flex-col w-full md:w-fit whitespace-normal
md:block md:flex-none md:space-x-5 md:whitespace-nowrap
`)`
  button {
    ${tw`mb-5 md:mb-0`}
  }
`;

interface ContainerProps {
  variant?: keyof typeof Button.variants
  disabled?: boolean
}

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  href?: never
}

interface LinkProps extends ComponentPropsWithoutRef<'a'> {
  /** Is set, render a link using {@link Link} rather than a button. */
  href: string
  type?: never
}

/**
 * Uses {@link https://dev.to/maissenayed/conditional-react-props-with-typescript-43lg conditional React props}
 * with TypeScript using
 * {@link https://www.typescriptlang.org/docs/handbook/2/narrowing.html#discriminated-unions discriminated unions}
 */
type Props = (ButtonProps | LinkProps) & PropsWithChildren & ContainerProps;

export default function Button(props: Props) {
  // If there is a href, renders a <Link> element using our router:
  if (isOfType<LinkProps>(props, 'href')) {
    const {
      href,
      disabled,
      variant,
      children,
      ...remainingProps
    } = props;

    const LinkContainer = StyledButton.withComponent(Link);

    return <LinkContainer
      variant={variant}
      to={href}
      disabled={disabled}
      {...remainingProps}
    >
      {children}
    </LinkContainer>;
  }

  const {
    type,
    disabled,
    variant,
    children,
    ...remainingProps
  } = props;

  // Otherwise renders a <button> element:
  return <StyledButton
    variant={variant}
    type={type ?? 'button'}
    disabled={disabled}
    {...remainingProps}
  >
    {children}
  </StyledButton>;
}
