import { PropsWithChildren, useState } from 'react';
import tw from 'twin.macro';
import TextInput from '@app/components/UI/Form/TextInput';
import Button, { ButtonGroup } from '@app/components/UI/Button';
import { User } from '@app/pages/Admin/ListUsers/ListUsers';

export interface Filters {
  externalId?: string | null
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  startDate?: Date|null
  endDate?: Date|null
}

interface Props {
  users: readonly User[]
  onSubmit: (filters: Filters) => void
}

export default function UsersFilters({
  users,
  onSubmit,
}: Props) {
  const [externalId, setExternalId] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [firstname, setFirstname] = useState<string | null>(null);
  const [lastname, setLastname] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date|null>(null);
  const [endDate, setEndDate] = useState<Date|null>(null);

  function clear(e) {
    setExternalId(null);
    setEmail(null);
    setFirstname(null);
    setLastname(null);

    onSubmit({});
    e.currentTarget.blur();
  }

  function submit(e) {
    e.preventDefault();
    e.currentTarget.blur();
    onSubmit({ externalId, email, firstname, lastname, startDate, endDate });
  }

  return <form className="mb-10 max-w-screen-lg">
    <FiltersFieldset value="Filtrer par références">
      <FiltersSection>
        <TextInput<string> id="externalId"
          label="Référence X3"
          value={externalId}
          onChangedValue={setExternalId}
          dataList={users.map(user => [user.uid, user.externalId])}
        />
        <TextInput<string> id="email"
          label="Email"
          value={email}
          onChangedValue={setEmail}
          dataList={users.map(user => [user.uid, user.email])}
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par info utilisateur">
      <FiltersSection>
        <TextInput<string> id="firstname"
          label="Prénom"
          value={firstname}
          onChangedValue={setFirstname}
          dataList={users.map(user => [user.uid, user.firstname])}
        />
        <TextInput<string> id="lastname"
          label="Nom"
          value={lastname}
          onChangedValue={setEmail}
          dataList={users.map(user => [user.uid, user.lastname])}
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par date d'ajout">
      <FiltersSection>
        <TextInput<Date> type="date" id="startDate" label="Début" value={startDate} onChangedValue={setStartDate} />
        <TextInput<Date> type="date" id="endDate" label="Fin" value={endDate} onChangedValue={setEndDate} />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersActions>
      <ButtonGroup>
        <Button variant="neutral" onClick={clear}>Vider les filtres</Button>
        <Button variant="primary" type="submit" onClick={submit}>
          Appliquer les filtres
        </Button>
      </ButtonGroup>
    </FiltersActions>
  </form>;
}

function FiltersFieldset({ value, children }: { value: string } & PropsWithChildren) {
  return <fieldset className="mb-1">
    <legend className="font-bold">{value}</legend>

    {children}
  </fieldset>;
}

const FiltersSection = tw.div`
  grid grid-cols-1 gap-2
  lg:(
    grid grid-cols-3 gap-4
  )
`;

const FiltersActions = tw.div`mt-8`;

export function filterUsers(users: User[], filters: Filters) {
  return users.filter(user => {
    if (filters.externalId && !user.externalId.includes(filters.externalId)) {
      return false;
    }

    if (filters.email && !user.email.includes(filters.email)) {
      return false;
    }

    if (filters.firstname && !user.firstname.includes(filters.firstname)) {
      return false;
    }

    if (filters.lastname && !user.lastname.includes(filters.lastname)) {
      return false;
    }

    if (filters.startDate && new Date(user.createdAt) < filters.startDate) {
      return false;
    }

    if (filters.endDate && new Date(user.createdAt) > filters.endDate) {
      return false;
    }

    // noinspection RedundantIfStatementJS
    return true;
  });
}
