import tw from 'twin.macro';
import { FormEvent, useState } from 'react';
import { FormRootErrors } from '@app/components/UI/Form/FormRootErrors';
import TextInput from '@app/components/UI/Form/TextInput';
import ToggleInput from '@app/components/UI/Form/ToggleInput';
import Button from '@app/components/UI/Button';
import { User } from '@app/models/types/User';
import { FormErrorsMap } from '@app/components/UI/Form';

export interface UserData {
  externalId?: string | null
  email?: string | null
  password?: string | null
  firstname?: string | null
  lastname?: string | null
  admin: boolean
}

interface Props {
  onSubmit: (payload: UserData) => void
  errors?: FormErrorsMap<keyof UserData>
}

interface CreateProps extends Props {
  initialData?: never
}

interface UpdateProps extends Props {
  initialData: User
}

export default function UserForm({
  onSubmit,
  initialData = undefined,
  errors = {},
}: CreateProps | UpdateProps) {
  const FormActions = tw.div`flex items-center justify-end mt-10`;

  const isUpdate = initialData !== undefined;

  const [externalId, setExternalId] = useState<string | null>(initialData?.externalId ?? null);
  const [email, setEmail] = useState<string | null>(initialData?.email ?? null);
  const [password, setPassword] = useState<string | null>(null);
  const [firstname, setFirstname] = useState<string | null>(initialData?.firstname ?? null);
  const [lastname, setLastname] = useState<string | null>(initialData?.lastname ?? null);
  const [admin, setAdmin] = useState<boolean>(initialData?.isAdmin ?? false);

  function submit(event: FormEvent<HTMLFormElement>): void {
    // Prevent default to avoid page reload.
    event.preventDefault();

    onSubmit({
      externalId,
      email,
      firstname,
      lastname,
      admin,
    });
  }

  return <form className="mb-10 max-w-screen-lg" onSubmit={submit}>
    <FormRootErrors errors={errors?.__root} />

    <fieldset className="mb-1">
      <legend className="font-bold">Identifiants</legend>

      <TextInput<string>
        id="email"
        label="Email"
        type="email"
        value={email}
        onChangedValue={setEmail}
        required
        InputProps={{
          autoFocus: true,
          autoComplete: 'email',
        }}
        errors={errors?.email}
      />
      <TextInput<string>
        id="externalId"
        label="Réf. X3"
        value={externalId}
        onChangedValue={setExternalId}
        required
        InputProps={{
          autoComplete: 'off',
        }}
        errors={errors?.externalId}
      />
      <TextInput<string>
        id="password"
        label="Mot de passe"
        type="password"
        value={password}
        onChangedValue={setPassword}
        InputProps={{
          placeholder: isUpdate
            ? 'Laisser vide pour conserver le mot de passe actuel'
            : 'Laisser vide pour générer un mot de passe aléatoire'
          ,
          autoComplete: 'new-password',
        }}
        errors={errors?.password}
      />
    </fieldset>

    <fieldset className="mb-1">
      <legend className="font-bold">Données personnelles</legend>

      <TextInput<string>
        id="firstname"
        label="Prénom"
        value={firstname}
        onChangedValue={setFirstname}
        required
        errors={errors?.firstname}
      />
      <TextInput<string>
        id="lastname"
        label="Nom"
        value={lastname}
        onChangedValue={setLastname}
        required
        errors={errors?.lastname}
      />
    </fieldset>

    <fieldset className="mb-1">
      <legend className="font-bold">Droits</legend>

      <ToggleInput id="admin" label="Droits administrateur" checked={admin} onChangedValue={setAdmin} />
    </fieldset>

    <FormActions>
      <Button type="submit" variant="primary">
        {isUpdate ? 'Modifier' : 'Créer'}
      </Button>
    </FormActions>
  </form>;
}
