import Button from '@app/components/UI/Button';

import DeposeImportMutation from '@graphql/mutation/import/DeposeImport.graphql';
import { useMutation } from '@app/api/apollo/useMutation';

interface MutationResponse {
  DeposeImport: {
    uid: string
  }
}

interface Props {
  uid: string
}

/**
 * Button to depose an import for X3 to process it.
 */
export default function DeposeImportButton({
  uid,
}: Props) {

  const [depose, { loading }] = useMutation<MutationResponse>(DeposeImportMutation, {
    variables: { uid },
    update() {
      // Noop: since the mutation is referring to an existing object in Apollo cache,
      // there is no need to add this object in any collection,
      // and the fields returned by the mutation are already updated automatically on the corresponding object.
    },
  });

  function onClick() {
    depose().then(() => {
      // noop : UI is updated by the Apollo cache update
    });
  }

  return <Button variant="primary" disabled={loading} onClick={onClick}>
    Déposer les fichiers X3
  </Button>;
}
